/* ----------------------------------------------------------------
		Custom CSS

		Add all your Custom Styled CSS here for New Styles or
		Overwriting Default Theme Styles for Better Handling Updates
	-----------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");

@font-face {
    font-family: "Poppins-Bold";
    src: url("./fonts/Poppins-Bold.eot");
    src: url("./fonts/Poppins-Bold.eot?#iefix") format("embedded-opentype"),
        url("./fonts/Poppins-Bold.woff2") format("woff2"),
        url("./fonts/Poppins-Bold.woff") format("woff"),
        url("./fonts/Poppins-Bold.ttf") format("truetype"),
        url("./fonts/Poppins-Bold.svg#Poppins-Bold") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poppins-Regular";
    src: url("./fonts/Poppins-Regular.eot");
    src: url("./fonts/Poppins-Regular.eot?#iefix") format("embedded-opentype"),
        url("./fonts/Poppins-Regular.woff2") format("woff2"),
        url("./fonts/Poppins-Regular.woff") format("woff"),
        url("./fonts/Poppins-Regular.ttf") format("truetype"),
        url("./fonts/Poppins-Regular.svg#Poppins-Regular") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poppins-Medium";
    src: url("./fonts/Poppins-Medium.eot");
    src: url("./fonts/Poppins-Medium.eot?#iefix") format("embedded-opentype"),
        url("./fonts/Poppins-Medium.woff2") format("woff2"),
        url("./fonts/Poppins-Medium.woff") format("woff"),
        url("./fonts/Poppins-Medium.ttf") format("truetype"),
        url("./fonts/Poppins-Medium.svg#Poppins-Medium") format("svg");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poppins-Light";
    src: url("./fonts/Poppins-Light.eot");
    src: url("./fonts/Poppins-Light.eot?#iefix") format("embedded-opentype"),
        url("./fonts/Poppins-Light.woff2") format("woff2"),
        url("./fonts/Poppins-Light.woff") format("woff"),
        url("./fonts/Poppins-Light.ttf") format("truetype"),
        url("./fonts/Poppins-Light.svg#Poppins-Light") format("svg");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poppins-Thin";
    src: url("./fonts/Poppins-Thin.eot");
    src: url("./fonts/Poppins-Thin.eot?#iefix") format("embedded-opentype"),
        url("./fonts/Poppins-Thin.woff2") format("woff2"),
        url("./fonts/Poppins-Thin.woff") format("woff"),
        url("./fonts/Poppins-Thin.ttf") format("truetype"),
        url("./fonts/Poppins-Thin.svg#Poppins-Thin") format("svg");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poppins-SemiBold";
    src: url("./fonts/Poppins-SemiBold.eot");
    src: url("./fonts/Poppins-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("./fonts/Poppins-SemiBold.woff2") format("woff2"),
        url("./fonts/Poppins-SemiBold.woff") format("woff"),
        url("./fonts/Poppins-SemiBold.ttf") format("truetype"),
        url("./fonts/Poppins-SemiBold.svg#Poppins-SemiBold") format("svg");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

* {
    font-family: "Poppins", sans-serif;
}

/**************************Slider***********************************/

.main-slider .slick-slide {
    border: none;
}

.main-slider .cashiback-slider-content {
    text-align: left;
    padding-left: 6rem;
    height: 100%;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}

.main-slider .cashiback-slider-content .slider-title {
    color: #fff;
    margin: 1rem 0;
    font-size: 36px;
    font-weight: 600;
    line-height: 1.53;
}

.main-slider .cashiback-slider-content .main-btn {
    background: #fff;
    color: #f0536c;
}

.parent-slider .main-slider .slick-slide {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.slider-item {
    margin: 0 auto;
}

@media (max-width: 991.98px) {
    .main-slider .cashiback-slider-content .slider-title {
        font-size: 30px;
    }
}

@media (max-width: 767.98px) {
    .main-slider .cashiback-slider-content {
        padding-left: 3rem;
    }
}

/****************************************************************************/

.small {
    font-family: "Poppins", sans-serif !important;
    font-size: 16px !important;
}

.active {
    color: #f05864 !important;
}

.user-menu i {
    padding: 5px;
}

.user-img-header {
    border-left: solid 2px #fff;
    padding-left: 10px;
}

.user-img-header img {
    width: 30px;
    border-radius: 50%;
}

.navbar-nav>.nav-item>.nav-link {
    color: #fff !important;
    /* margin-top: 20px; */
}

.navbar-nav>.nav-item>.nav-link:hover {
    color: #8d4747;
}

.navbar-nav>.nav-item>.nav-link small {
    font-size: 16px !important;
    cursor: pointer;
    margin: 0 0.5rem;
    white-space: nowrap;
}

.main-header {
    background: linear-gradient(90deg,
            rgba(239, 79, 112, 1) 7%,
            rgba(242, 109, 76, 1) 84%);
}

.mainLogo {
    width: 193.1px;
    height: 35.5px;
}

.search-box {
    overflow: hidden;
    border-radius: 30px;
    border: solid 1px rgba(206, 207, 208, 0.21);
    background-color: #ffffff;
    margin: 7px auto;
    height: 50px;
}

.nav-item-search {
    height: 50px;
    display: flex;
    align-items: center;
}

.search-input {
    border: none;
    padding: 0 30px;
    width: 100%;
}

.search-input::placeholder {
    color: #a7a9ac;
}

.btn-search {
    width: 30px;
    height: 30px;
    border-radius: 50% !important;
    background-color: #ededed;
    margin: 5px 10px;
}

button.btn.btn-search i.icon-line-search {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    color: #a7a9ac;
}

.btn:focus,
.btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(97, 104, 110, 0.25);
}

.menu-link div {
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: #1d1d1d;
}

.icon-line-search:before {
    font-size: 14px;
}

.form-inline .input-group,
.form-inline .custom-select {
    width: 100%;
    margin: 0;
}

.nav-link i {
    font-size: 16px;
    margin-right: 5px;
    vertical-align: middle;
}

.nav-link i.icon-world-cust {
    margin-right: 16px;
}

.country-flag-div {
    display: flex;
    justify-content: flex-start;
}

.country-flag-div:hover i,
.country-flag-div:hover .flag-text {
    color: #f05864;
}

.flag-img {
    width: 30px;
    height: 30px;
    margin: 0 8px 0 0;
}

.egypt-flag {
    width: 23px;
    height: 23px;
    margin: 0 10px 0 4px;
}

.icon-world-cust {
    font-size: 22px !important;
    margin: 0 10px 0 4px;
}

.flag-text {
    font-size: 15px;
    text-align: center;
    padding: 3px 0;
    margin-left: 13px;
}

.flag-text:hover {
    color: #f05864 !important;
}

.header-buttons {
    padding: 0 0 2px 12px;
    height: 30px;
    border-left: 2px solid rgba(255, 255, 255, 0.4);
}

.header-buttons .sign-in {
    color: #fff;
    white-space: nowrap;
}

.join-btn {
    border: 1px solid #fff !important;
    border-radius: 10px !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-transform: none;
    margin-top: 14px !important;
}

.join-btn>small {
    font-weight: 500 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.56 !important;
    letter-spacing: 0.5px !important;
    text-align: center;
}

.help-icon {
    font-size: 26px !important;
    margin-top: -5px !important;
}

a.nav-link.second-nav-nav-link {
    height: 50px !important;
    padding: 12px 0 10px;
    margin: 0 12px;
}

a.nav-link.second-nav-nav-link>small:hover {
    color: #f05864;
}

header>.container {
    /* max-width: 100% !important; */
}

@media only screen and (max-width: 768px) {
    .mainLogo {
        margin-left: 15px;
    }

    .search-box {
        margin-bottom: 15px !important;
    }

    .sub-menu-container .nav-item>.nav-link {
        padding: 0px 5px;
    }
}

@media only screen and (max-width: 992px) {
    .no-border-buttom {
        border-bottom: none !important;
    }

    .no-background-drop-menu,
    .no-background-drop-menu:hover {
        background-color: transparent !important;
        border: none !important;
    }

    .form-inline .input-group,
    .form-inline .custom-select {
        /* width: 80%; */
        margin: auto;
    }

    .nav-item:not(:first-child) {
        border-top: none !important;
    }

    .main-header {
        /* padding: 8px 0 15px; */
        min-height: 60px;
    }

    /* .active {
        color: #fff !important;
      } */

    .flag-text {
        color: #fff;
        opacity: 1 !important;
    }

    .flag-text:hover {
        color: #8d4747 !important;
    }

    .categories-li {
        margin-left: 13px;
    }

    .join-btn {
        margin-left: 10px !important;
    }
}

@media only screen and (min-width: 992px) {
    /* .sub-menu-container:not(.mega-menu-column) {
        width: 133px;
        height: 212px;
        border-radius: 6px;
      } */

    .region-menu,
    user-menu {
        width: 133px;
        height: 212px;
        border-radius: 6px;
        border: none;
        box-shadow: 0 13px 66px 0 rgba(0, 0, 0, 0.16);
    }

    .user-menu {
        left: auto;
    }

    .mainLogo {
        margin: 0;
    }

    .main-header {
        /*padding: 8px 0 15px;*/
        /* padding: 0 0 3px; */
    }

    .center-nav {
        /* padding: 0 5% !important; */
    }

    .sec-menu-container {
        padding-top: 25px;
        display: flex;
        justify-content: flex-start;
    }

    .categories-li {
        margin-right: 5px;
        margin-left: -25px !important;
        padding-left: 0 !important;
    }
}

@media only screen and (min-width: 1285px) {

    .form-inline .input-group,
    .form-inline .custom-select {
        /* width: 25em; */
    }
}

.first-slide .row {
    background-image: url(../images/home/bg-1.png);
    background-size: cover;
    object-fit: contain;
}

/* .bg-gradient {
      background: linear-gradient(to top, #ffffff 0, #ededed 100%);
      width: 1920px;
      height: 574px;
    } */
section.parent-slider {
    width: 100%;
    margin: 0 auto !important;
}

.carousel-inner {
    overflow: hidden !important;
    border-radius: 10px;
}

.carousel-inner .row {
    min-height: 65vh !important;
}

.image {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.slide-text {
    position: relative;
    top: 6rem;
    left: 6rem;
}

.slide-text h1 {
    font-size: 43px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.49;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
}

.slide-text p {
    font-family: Poppins;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
}

.slide-text button {
    width: 200.6px;
    height: 62.4px;
    border-radius: 7px;
    background-color: #ffffff;
    border: none;
}

span.icon-prev {
    position: absolute;
    top: 50%;
    left: -6%;
}

span.icon-next {
    position: absolute;
    top: 50%;
    right: -6%;
}

.carousel-control i {
    background: #dedede;
    padding: 10px 10px;
    border-radius: 20px;
    border: 0.5px solid #acabab;
}

.carousel-control i:before {
    font-size: 21px !important;
    color: #a7a9ac;
}

.second-slide .row {
    background-image: url(../images/home/bg-2.png);
    background-size: cover;
    object-fit: contain;
}

.second-slide .slide-text h1,
.second-slide .slide-text p,
.third-slide .slide-text h1,
.third-slide .slide-text p {
    color: #4e4e4e;
}

.third-slide .row {
    background-image: url(../images/home/bg-3.png);
    background-size: cover;
    object-fit: contain;
}

.fourth-slide .row {
    background-image: url(../images/home/bg-2.png);
    background-size: cover;
    object-fit: contain;
}

.carousel-control-prev,
.carousel-control-next {
    opacity: 0.9 !important;
    top: 45%;
    height: 100px;
}

.carousel-control-next-icon:after {
    content: ">";
    width: 18px;
    height: 21px;
    font-family: FontAwesome5Pro;
    font-size: 25px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: left;
    color: #a7a9ac;
    bottom: -13px;
    position: relative;
    font-weight: bold;
}

.carousel-control-prev-icon:after {
    content: "<";
    width: 18px;
    height: 21px;
    font-family: FontAwesome5Pro;
    font-size: 25px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: left;
    color: #a7a9ac;
    bottom: -13px;
    position: relative;
    font-weight: bold;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    height: 60px;
    width: 60px;
    background-size: 100%, 100%;
    border-radius: 50%;
    background-image: none;
    background: #fff !important;
    opacity: 1 !important;
    border: 1px solid #9c9c9c;
}

a.carousel-control-next {
    right: -9rem;
}

a.carousel-control-prev {
    left: -9rem;
}

/* start */
/* start carousel style  */

button.btn.btn-danger {
    width: 135px;
    height: 50px;
    border-radius: 8px;
    background: linear-gradient(90deg,
            rgba(239, 79, 112, 1) 7%,
            rgba(242, 109, 76, 1) 84%);
}

button.btn.btn-danger:hover,
.join button.btn.btn-secondary:hover,
.single-pro button.btn-danger:hover {
    box-shadow: 2px 12px 20px -10px rgba(148, 145, 148, 1);
}

/* .slick-slide div a {
    padding-top: 1.3rem;
  } */

.slick-slide:focus,
.slick-slide a:focus {
    border: none !important;
    outline: none !important;
}

.slick-slide {
    border-radius: 12px;
    border: solid 1px #e4e5e6;
    background-color: #ffffff;
    text-align: center;
}

.slick-slide div p {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: #1d1d1d;
    margin-top: 1rem;
    margin-bottom: 0;
}

.slick-active {
    opacity: 1 !important;
}

.slick-slide i:before {
    font-size: 27px;
}

.slick-next {
    right: 12px;
}

ul.slick-dots {
    display: none !important;
}

.responsive .slider-link img {
    width: 30px;
    /* margin: 10px auto 7px; */
}

.slick-slide div a {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* store item  */
/*store-icon:before {
      color: #f26b4e;
      background: rgb(242 108 78 / 25%);
      padding: 12px 10px;
      border-radius: 100%;
    }*/
.slick-slide .popular>.slider-link {
    background: rgb(242 108 78 / 25%);
}

.slick-current .popular {
    border: 2px solid #f26b4e;
    border-radius: 12px;
    color: #f26d4c;
}

/* electronic item */
.fa-camera:before {
    color: #32ea32;
    background: rgb(50 234 50 / 33%);
    padding: 13px;
    border-radius: 100%;
}

.slick-slide .electronic>.slider-link {
    background: rgb(50 234 50 / 33%);
}

.slick-current .electronic {
    border: 2px solid #32ea32;
    border-radius: 12px;
    color: #32ea32;
}

/* dinning item */
.fa-hamburger:before {
    color: #c365da;
    background: #c365da54;
    padding: 13px;
    border-radius: 100%;
}

.slick-slide .dining>.slider-link {
    background: #c365da54;
}

.slick-current .dining {
    border: 2px solid #c365da;
    border-radius: 12px;
    color: #c365da;
}

/* fashion item  */

.fa-tshirt:before {
    color: #4343de;
    background: #0000ff0d;
    padding: 11px 9px;
    border-radius: 100%;
}

.slick-slide .fashion>.slider-link {
    background: #0000ff0d;
}

.slick-current .fashion {
    border: 2px solid #4343de;
    border-radius: 12px;
    color: #4343de;
}

/* delivery item  */
.fa-shuttle-van:before {
    color: #ef4f70;
    background: #f091a480;
    padding: 11px 9px;
    border-radius: 100%;
}

.slick-slide .delivery>.slider-link {
    background-color: #23ce6b40;
}

.slick-current .delivery {
    border: 2px solid #ef4f70;
    border-radius: 12px;
    color: #ef4f70;
}

/* groceries item  */
.fa-shopping-basket:before {
    color: #62bade;
    background: #62bade33;
    padding: 13px;
    border-radius: 100%;
}

.slick-slide .groceries>.slider-link {
    background: #62bade33;
}

.slick-current .groceries {
    border: 2px solid #62bade;
    border-radius: 12px;
    color: #62bade;
}

/* health item  */
.slider-link .fa-shield-alt:before {
    color: #cac538cc;
    background: #cac5385b;
    padding: 11px 9px;
    border-radius: 100%;
}

.slick-slide .health>.slider-link {
    background: #cac5385b;
}

.slick-current .health {
    border: 2px solid #cac538cc;
    border-radius: 12px;
    color: #cac538cc;
}

.slick-slide .selling-store>.slider-link {
    border-radius: 5em;
    width: 60px;
    height: 60px;
    margin: auto;
}

.selling-store {
    display: flex;
    flex-direction: column;
    border: solid 1px #e4e5e6;
    border-radius: 12px;
}

/* products section */
.container-relative {
    position: relative;
}

.bg-gradient-1 {
    position: absolute;
    width: 100%;
    height: 100%;
    top: -159px;
    background: linear-gradient(to top, #ffffff 0, #ededed52 100%);
    z-index: -1;
}

.bg-gradient {
    position: absolute;
    width: 100%;
    height: 98%;
    top: -67px;
    background: linear-gradient(to top, #ffffff 0, #ededed52 100%);
    z-index: -1;
}

.products-border {
    border-top: 0.5px solid #dedede;
}

.products-cards {
    position: relative;
}

.search .grid-inner:hover,
.products .single-pro:hover {
    box-shadow: 3px 10px 22px -15px rgba(148, 145, 148, 1);
}

.col.single-pro {
    text-align: center;
}

.image {
    margin: 10px auto;
}

.products .single-pro h3 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #1d1d1d;
    margin-bottom: 10px;
}

.single-pro p {
    color: #f05864;
    font-weight: 600;
    text-align: center;
}

/* .col.single-pro:hover {
    box-shadow: 0px 0px 5px #ccc;
  } */

.products-cards .single-pro p {
    margin-top: -20px;
}

.products-cards .single-pro h3 {
    margin-top: -50px;
}

.single-pro span {
    text-align: center !important;
    display: block;
}

.airbnb img {
    margin-top: -1.9rem;
}

.single-pro span {
    font-size: 12px;
}

.products-cards h2 {
    font-family: Poppins;
    font-size: 32px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #1d1d1d;
}

.products-cards .col-9 p {
    font-family: Poppins;
    font-size: 25px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.88;
    letter-spacing: normal;
    text-align: left;
    color: #949698;
    margin-top: -18px;
}

.card-body h3 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #1d1d1d;
}

.products-cards .single-pro:hover span,
.products-cards .single-pro:hover i:before {
    color: #f26d4c;
}

.products-cards .single-pro:hover i {
    background: #f26d4c59;
}

/* i.fas.fa-stopwatch {
      width: 18px;
      height: 18px;
      background-color: #e5e5e5;
      padding: 3px 2px;
      border-radius: 20px;
    } */

/* i.fas.fa-stopwatch:before {
      text-align: center;
      color: #b7b4b4;
    } */

/* start mobile store section */

.mobilestore {
    display: flex;
    /* justify-content: flex-start; */
    background-image: url(../images/home/store-bg.png);
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    /* height: 50vh; */
}

.mobilestore-sub {
    display: flex;
    /* justify-content: flex-end; */
    /* padding: 5% 1% 3% 33%; */
}

.text-mobileStore {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.mobilestore h2 {
    font-size: 2.25rem;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 0;
}

.mobilestore p {
    font-size: 1.25rem;
    color: #ffffff;
    margin-bottom: 0;
}

.btns-mobileStore {
    display: flex;
    justify-content: center;
    /* padding-top: 79px;
    padding-left: 1%; */
}

.single-btn-mobileStore {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: solid 1px #dddddd;
    background-color: #ffffff;
    padding: 15px 20px;
    margin: 0 8px;
}

@media only screen and (max-width: 1220px) {
    .mobilestore {
        /* background: url(../images/home/store-bg.png);
      background-size: cover;
      height: 40vh; */
    }

    .mobilestore-sub {
        padding-left: 0;
    }

    .text-mobileStore {
        margin-left: 0;
    }

    .mobilestore {
        justify-content: center;
    }

    .mobilestore-sub {
        justify-content: center;
    }

    .mobilestore h2 {
        margin-top: 0;
        font-size: 1.5rem;
    }

    .mobilestore p {
        font-size: 1rem;
    }

    .btns-mobileStore {
        /* padding-top: 6rem;
      padding-left: 2rem; */
    }
}

@media only screen and (max-width: 1024px) {
    .mobilestore-sub {
        flex-direction: column;
    }

    .btns-mobileStore {
        /* padding-top: 5px; */
    }

    .single-btn-mobileStore {
        margin: 5px;
    }

    .mobilestore h2 {
        text-align: center;
    }

    .mobilestore p {
        text-align: center !important;
    }
}

@media only screen and (max-width: 768px) {
    .btns-mobileStore {
        flex-direction: column;
    }

    .single-btn-mobileStore {
        margin: 0 auto 5px !important;
    }

    /* .mobilestore {
    height: 60vh;
  } */
}

@media only screen and (min-width: 1700px) {
    .btns-mobileStore {
        /* padding-top: 100px !important; */
    }

    .mobilestore-sub {
        display: flex;
        padding: 6% 0 5% 33%;
    }
}

/* end mobile store section */

/* start discover section */
.join {
    background: url(../images/home/discover-bg.png);
}

.join h2,
.discover h2 {
    font-family: Poppins;
    font-size: 32px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;

    color: #1d1d1d;
}

.join p,
.discover p {
    font-size: 18px;
    color: #949698;
}

.join button.btn.btn-secondary {
    width: 258px;
    height: 60px;
    border-radius: 7px !important;
    background-color: #e4e4e5 !important;
    display: block;
    margin: 10px auto;
    padding: 7px 50px;
    border: none;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: 0.5px;
    text-align: center;
    color: #1d1d1d;
}

/* start footer */
.footer {
    background-color: #333;
}

@media only screen and (max-width: 768px) {}

.logo-col p {
    color: #dedede;
    opacity: .7;
}

.newsletter-input.form-control {
    height: auto !important;
}

.input-group.subscribe-cont {
    width: 85% !important;
}

.newsletter p {
    opacity: 0.8;
    color: #ffffff;
}

.subscribe input[type="text"] {
    height: 60px;
    /* text-align: center; */
    border-radius: 10px 0px 0px 10px;
    border: solid 1px rgba(206, 207, 208, 0.21);
    background-color: #ffffff;
    padding-left: 54px;
}

.subscribe button {
    width: 89px;
    height: 60px;
    background-image: linear-gradient(135deg, #f0536c 21%, #f37246 104%);
    border: none;
    border-radius: 0px 10px 10px 0px;
}

.subscribe i.fa-envelope {
    position: absolute;
    top: 1.5rem;
    left: 4.2rem;
    font-size: 0.9rem;
    color: #757575;
}

.socialmedia i {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.53;
    color: #fff;
    display: inline-table;
}

/* .socialmedia i:hover {
    color: orange;
    cursor: pointer;
  } */

.copyrights {
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 0.5px solid #636363;
}

.bottom-nav .navbar {
    padding: 0 1rem;
    /* justify-content: space-around; */
    /* height: 45px; */
}

@media only screen and (max-width: 768px) {
    .bottom-nav .navbar {
        /* justify-content: end; */
        /* height: 60px; */
        z-index: 99999;
    }

    /* .menu-container {
    background: #fff;
  } */
}

header .navbar-nav .mr-5 {
    margin-right: 0rem !important;
}

.sub-menu-container .menu-item>.menu-link {
    padding: 4px 15px;
}

.menu-link {
    padding: 12px 1px;
}

.menu-link div {
    font-size: 14px;
}

.menu-link i {
    vertical-align: middle;
}

.menu-link i.icon-line-user-plus,
.menu-link i.icon-shop,
.menu-link i.icon-star21,
.menu-link i.icon-ticket-alt,
.menu-link i.icon-line-target {
    font-size: 17px;
}

.sub-menu-container .menu-item:hover>.menu-link div,
.menu-item:hover>.menu-link div,
.menu-item.current>.menu-link div {
    color: #f05864;
}

header .navbar-nav .primary-menu>.menu-container>li:first-child {
    margin-right: 2rem !important;
}

#header {
    /* border-bottom: 1px solid #f5f5f5 !important;
  margin-bottom: 15px; */
}

.text-secondary {}

.products>.mt-5 {
    border-bottom: 1px solid #eeeeee;
}

/* sign up model css */
.card.mx-auto {
    margin: 20px;
}

.left-side {
    float: left;
}

.right-side {
    float: right;
}

.card-header {
    text-align: center;
    background-image: url(../images/sign-in/model-header.svg);
    background-size: contain;
    background-repeat: no-repeat;
}

.header-icon {
    width: 45px;
    height: 45px;
    box-shadow: 0 26px 36px 0 rgba(188, 47, 58, 0.44);
    background: linear-gradient(157deg, #f26d4c 22%, #f05864 96%);
    border-radius: 50%;
    margin: 10px auto;
}

.fas.fa-id-badge {
    width: 25px;
    height: 33px;
    font-size: 33px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    margin-top: 32px;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
}

.card-header h2 {
    font-family: Poppins;
    font-size: 22px;
    line-height: 2em;
    letter-spacing: normal;
    text-align: center;
    color: #2c2c2c;
    margin-bottom: 0;
}

.card-header P {
    font-size: 18px;
}

.modal-card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
}

.box-model {
    border-radius: 8px;
    background-image: linear-gradient(121deg, #f26d4c 14%, #f05864 94%);
    padding: 30px;
    color: #fff;
    position: relative;
    margin-bottom: 20px;
}

.box-model h3 {
    color: #fff;
    font-size: 22px;
    margin-bottom: 0;
    line-height: 1.59;
}

.icon-body {
    width: 40px;
    height: 40px;
    box-shadow: 0 26px 36px 0 rgba(188, 47, 58, 0.44);
    background-image: linear-gradient(157deg, #ffffff 22%, #ffd1c6 96%);
    border-radius: 50%;
    text-align: center;
    position: absolute;
    top: 33%;
    left: 5%;
}

.fas.fa-wallet {
    color: #f05864;
    /* line-height: 2.5em; */
}

/* Full-width Sign up */
.sign-up-form input,
.sign-up-form select {
    width: 100%;
    padding: 15px;
    display: inline-block;
    border: solid 1px #e8e8e8;
    background-color: #f8f8f8;
    font-size: 14px;
    border-radius: 6px;
}

.right-side input[type="tel"] {
    padding-left: 90px;
}

.input-group-prepend {
    position: absolute;
    height: 54px;
    margin-top: 4px;
}

select {
    appearance: none;
    border: none;
    background: url(../images/sign-in/dropdown.png) 55px 22px no-repeat;
    width: 70px;
    height: 58px;
    padding: 5px;
    color: rgba(44, 44, 44, 0.8);
}

.checkbox-style,
.radio-style {
    opacity: 0;
    position: absolute;
}

input[type="radio"],
input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
}

.checkbox-style,
.radio-style,
.checkbox-style-1-label,
.radio-style-1-label,
.checkbox-style-2-label,
.radio-style-2-label,
.checkbox-style-3-label,
.radio-style-3-label {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    cursor: pointer;
}

.checkbox-style:checked+.checkbox-style-3-label::before,
.radio-style:checked+.radio-style-3-label::before {
    content: "\e92b";
    font-family: "lined-icons";
    background: #f05864;
    color: #fff;
    border: none;
    border-radius: 3px;
}

.checkbox-style-1-label::before,
.radio-style-1-label::before,
.checkbox-style-2-label::before,
.radio-style-2-label::before,
.checkbox-style-3-label::before,
.radio-style-3-label::before {
    content: "";
    background: #fff;
    border: 2px solid #ddd;
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    padding: 4px;
    margin-right: 10px;
    line-height: 1;
    text-align: center;
}

.signupbtn {
    margin: 0 auto;
    width: 50%;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

.right-side label,
.modal-card-body label,
.reset-password label,
.sign-up-form label {
    text-transform: capitalize;
    letter-spacing: 0;
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 5px;
    color: #2c2c2c;
    opacity: 0.8;
}

/* Footer modal */
.model-footer {
    text-align: center;
    margin-bottom: 30px;
}

.model-footer .main-btn {
    width: 240px !important;
    margin-bottom: 20px;
}

@media screen and (max-width: 300px) {
    .signupbtn {
        width: 100%;
    }
}

/* Terms and Conditions Modal */
.fas.fa-file-alt,
.fas.fa-user-lock {
    height: 33px;
    font-size: 33px;
    font-stretch: normal;
    font-style: normal;
    line-height: 3em;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
}

.fas.fa-long-arrow-alt-left {
    position: absolute;
    left: 3%;
    width: 25px;
    height: 33px;
    font-size: 33px;
    color: #2c2c2c;
}

/* forget password Modal */
.forget_password {
    color: #f05864;
    position: absolute;
    right: 29px;
    bottom: 15px;
    transition: .2s all ease-in-out;
}

@media screen and (max-width: 576px) {
    .forget_password {
        font-size: 11px;
    }
}

.rest-message {
    height: 63px;
    background-color: #dcffd8;
    padding: 10px;
    display: flex;
    color: #2c2c2c;
    margin-bottom: 30px;
    margin-top: 30px;
}

.fas.fa-check-circle {
    color: #27b118;
    margin-top: 13px;
    margin-right: 10px;
}

/* Rest password page */
.reset-password h2 {
    margin-bottom: 10px;
}

.reset-password .btn.btn-danger {
    width: auto !important;
    margin-top: 15px;
}

/* change password page */
.change-password {
    text-align: center;
}

.change-password h2 {
    margin-bottom: 0;
}

.change-password .btn.btn-danger {
    width: 200px !important;
}

.check-img {
    background: #27b118;
    padding: 20px;
    width: 80px;
    border-radius: 50%;
    box-shadow: 2px 3px 31px 9px rgba(14, 139, 31, 0.44);
    margin: 30px auto;
}

/* search
    ============*/
.search .grid-inner {
    border: solid 1px #e4e5e6;
    border-radius: 8px;
    padding: 30px;
}

.search .product-desc a {
    font-size: 16px;
    font-weight: 500;
    color: #1d1d1d;
}

.search .discount {
    color: #f05864;
    margin-bottom: 0;
}

.search .product-image {
    width: 85%;
    margin: 0 auto;
}

.search .product-desc {
    padding: 15px 0 0;
}

.plat-form {
    margin: 10px 0px;
}

.plat-form .col-sm-8 {
    border-radius: 24px;
    background-color: #f8f8f8;
    padding: 10px 5px;
    display: inline;
}

.plat-form img {
    background: #fff;
    padding: 7px;
    border-radius: 50%;
    margin-left: 5px;
}

.plat-form .col-sm-8 p {
    font-size: 14px;
    display: inline;
    font-weight: 500;
}

.plat-form .col-sm-4 {
    display: inline;
    padding-left: 15px;
}

.plat-form .col-sm-4 i {
    color: #f0c758;
}

.plat-form .col-sm-4 p {
    font-size: 14px;
    display: inline-block;
    margin-bottom: 0;
}

.search.sidebar.col-lg-3 {
    border: solid 1px #e4e5e6;
    border-radius: 10px;
}

.search-page.widget.clearfix {
    border: solid 1px #e4e5e6;
    border-radius: 10px;
    padding: 20px;
}

.search-page.widget h4 {
    font-size: 18px;
    text-transform: capitalize;
}

.search-page .checkbox-style-1-label:before,
.search-page .radio-style-1-label:before,
.search-page .checkbox-style-2-label:before,
.search-page .radio-style-2-label:before,
.search-page .checkbox-style-3-label:before,
.search-page .radio-style-3-label:before {
    width: 18px;
    height: 18px;
    border: 1px solid #e4e5e6;
    border-radius: 5px;
}

.checkbox-style:checked+.checkbox-style-2-label:before {
    background: #f05864;
    box-shadow: inset 0px 0px 0px 4px #fff;
}

.search-page label.checkbox-style-2-label {
    font-size: 18px;
    font-weight: 300;
}

.search-filter label {
    display: inline-block;
}

.search-filter select {
    display: inline-block;
    width: 65%;
}

.search-main-txt {
    font-size: 22px;
    font-weight: 600;
}

span.light-txt {
    font-weight: 300;
}

.search-main-icons i {
    padding-left: 25px;
    font-size: 22px;
}

/* list style */
.nav-tabs {
    border-bottom: none;
}

.search-main-icons i {
    padding-left: 0 !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-link:hover {
    border-color: transparent;
}

.nav-link {
    display: block;
    /* padding: 0.5rem 0.4rem !important; */
}

.product-desc_list {
    width: 78%;
}

.rate_list {
    text-align: right;
}

.price_list {
    background-color: transparent !important;
}

.product-title a {
    color: #000;
}

.plat-form .float-end {
    font-weight: bold;
}

.list_block {
    border: solid 1px #e4e5e6;
    border-radius: 8px;
    margin-bottom: 30px;
}

.list_block:hover {
    box-shadow: 3px 10px 22px -15px rgba(148, 145, 148, 1);
}

.nav-item:not(:first-child) {
    margin: 0px 7px;
}

section.no-result img {
    margin: 0 auto;
    display: block;
}

section.no-result h3,
section.no-result p {
    text-align: center;
    margin-top: 20px;
}

/* Aliexpress Cashback
    ========================*/
.bg-img {
    background-image: url(../images/home/footer.png);
    background-size: cover;
}

.shop-bg {
    /* width: 85%; */
    border-radius: 8px;
    box-shadow: 0 13px 66px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    top: 6rem;
    background: #fff;
}

.shop-bg img {
    max-width: 100px;
}

.shop-bg h2 {
    font-family: Poppins;
    font-size: 28px;
    font-weight: 600;
    color: #2c2c2c;
    margin-bottom: 0.75rem;
}

.shop-bg p {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    color: #f05864;
}

.btn-danger.btn-danger {
    width: 211px;
    height: 61px;
    border-radius: 8px;
    background: linear-gradient(179deg, #f36e4c 0, #f05864 100%);
    border: none;
    outline: none;
}

#more {
    display: none;
}

.about-box-express h1 {
    font-size: 18px;
    font-weight: 600;
    color: #2c2c2c;
}

.about-box-express i.fa-info {
    font-size: 15px;
    color: #2c2c2c;
    border: 1px solid;
    border-radius: 5em;
}

.about-box-express p {
    opacity: 0.8;
    font-size: 15px;
    color: #1d1d1d;
}

button#myBtn {
    font-size: 16px;
    color: #f05864;
    background: no-repeat;
    border: none;
}

#myBtn .fa-plus {
    font-size: 12px;
    letter-spacing: normal;
    text-align: left;
    color: #f05864;
}

.get-cash-back {}

.get-cash-back h1 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #2c2c2c;
}

.get-cash-back i.fas.fa-clipboard {
    width: 18px;
    height: 21px;
}

p.btn-title {
    opacity: 0.8;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;
    text-align: center;
    color: #f05864;
    margin-bottom: 1rem;
}

.btn-cashback {
    width: 150px;
    height: 52px;
    border-radius: 3px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
    background-color: #f7f7f7;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    position: relative;
}

.line-after:after {
    content: "";
    width: 2px;
    height: 30px;
    background: #d0d0d0;
    position: absolute;
    display: block;
    top: 3.8rem;
    left: 4.5rem;
}

.cash-terms {}

.deals-p .fa-tag:before {
    font-size: 14px;
    padding-right: 7px;
    color: #848080;
}

/* trending stores box  */
.trending-stores {}

.trending-stores .fa-store {
    font-size: 20px;
    color: #2c2c2c;
}

.trending-stores h1 {
    font-size: 18px;
    font-weight: 600;
    color: #2c2c2c;
}

.single-trend .img-box {
    width: 68px;
    height: 68px;
    border-radius: 8px;
    border: solid 1px #e4e5e6;
    background-color: #ffffff;
}

.single-trend .img-box img {
    display: block;
    margin: 11px auto;
}

.single-trend h2 {
    font-size: 18px;
    font-weight: 600;
    color: #1d1d1d;
}

.single-trend p {
    font-size: 15px;
    font-weight: 500;
    color: #f05864;
    padding-left: 80px;
    margin-top: -30px;
}

button.btn-category {
    border-radius: 8px;
    background-color: #f7f7f7;
    font-size: 15px;
    font-weight: 500;
    color: #1d1d1d;
    border: solid 1px transparent;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 16px 20px;
    align-items: center;
    transition: 0.2s all ease-in-out;
    text-align: left;
}

.btn-category span {
    font-size: 16px;
    font-weight: 600;
    color: #f05864;
}

button.btn-category:hover,
button.btn-category:active {
    border-radius: 8px;
    box-shadow: 3px 2px 8px 0 rgba(0, 0, 0, 0.06);
    border: solid 1px #e4e5e6;
    background-color: #ffffff;
    color: #f05864;
}

/*category tabs style  */
div#myBtnContainer {
    border-radius: 8px;
    background-color: #f8f8f8;
}

.category-tabs .row:after {
    content: "";
    display: table;
    clear: both;
}

#myBtnContainer .btn {
    border: none;
    outline: none;
    padding: 17px 0;
    margin: 0 12px;
    cursor: pointer;
}

#myBtnContainer .btn:focus {
    outline: none !important;
    box-shadow: none;
}

#myBtnContainer .btn:hover {
    outline: none !important;
}

#myBtnContainer .btn.active {
    outline: none !important;
    position: relative;
}

#myBtnContainer .btn.active:after {
    content: "";
    background-color: #f05864;
    height: 2px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.column {
    float: left;
    /*margin: 2rem 1rem 2rem 2rem;*/
    margin: 0.5rem 2rem;

    display: none;
    /* Hide all elements by default */
}

.show {
    display: block;
}

.content {
    border-bottom: 1px solid #ddd;
    /*padding-bottom: 1rem;*/
}

p.coupons-p {
    margin-bottom: 0.5rem;
}

.content h1 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #2c2c2c;
}

.up-to {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: #f05864;
    margin-top: -1rem;
}

.content button.btn.btn-danger {
    width: 159px;
    height: 61px;
    border-radius: 8px;
    margin-top: 1rem;
}

span.deals-p {
    margin-right: 1rem;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: #2c2c2c;
}

.expire {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: #f37246 !important;
}

.expire .expire-icon {
    background-color: none !important;
}

.deals i.expire-icon:before {
    color: #f37246 !important;
    width: 12px !important;
    height: 14px !important;
    background-color: #f3724640 !important;
    padding: 3px;
    border-radius: 50px;
}

h1.section-title {
    font-family: Poppins;
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #2c2c2c;
}

/* category tabs style  */

@media screen and (max-width: 768px) {
    .shop-bg .col-lg-4 {
        margin-top: -1rem !important;
        margin-left: 4rem !important;
    }

    .shop-bg button.btn.btn-danger {
        width: 145px;
        height: 50px;
        margin-bottom: 1rem;
    }

    .get-cash-back,
    .trending-stores,
    .about-box-express {
        width: 100% !important;
    }
}

@media screen and (max-width: 992px) {
    button.btn-category {
        height: 100%;
        padding: 8px;
    }
}

/* Favourit, Top Cash, Store Coupons
    ======================================*/
h1.main-title {
    font-family: Poppins;
    font-size: 25px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #2c2c2c;
}

p.text-left.secondary-text {
    font-family: Poppins;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #949698;
    margin-bottom: 0.5rem;
}

.products .single-pro h3 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #1d1d1d;
    margin-bottom: 10px;
}

.single-pro p {
    color: #f05864;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0.75rem;
    font-size: 15px;
}

.products .single-pro .image {
    margin: 0;
}

.products-cards .single-pro p {
    margin-top: -20px;
}

.products-cards .single-pro h3 {
    margin-top: -50px;
}

.single-pro span {
    text-align: center !important;
    display: block;
}

.airbnb img {
    margin-top: -1.9rem;
}

.single-pro span {
    font-size: 12px;
}

.single-pro h3 {
    text-align: center;
    margin-bottom: 1rem;
}



.card-body h3 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #1d1d1d;
}

.products-cards .single-pro:hover span,
.products-cards .single-pro:hover i:before {
    color: #f26d4c;
}

.products-cards .single-pro:hover i {
    background: #f26d4c59;
}

.home-expire-icon i.fa-stopwatch {
    width: 18px;
    height: 18px;
    background-color: #e5e5e5;
    padding: 3px 2px;
    border-radius: 20px;
    transition: 0.1s all ease-in-out;
}

.single-pro i.fas.fa-stopwatch:before {
    text-align: center;
    color: #b7b4b4;
}

.image img {
    /* display: block;
    margin: 0px auto !important;
    margin-top: 15px !important; */
}

/* .single-pro i:before {
      width: 39px;
      height: 39px;
      background-color: rgba(240, 88, 100, 0.1);
      padding: 10px;
      border-radius: 20px;
      color: #f05864;
    }
    .single-pro i {
      position: relative;
      top: 1rem;
    } */

.single-pro button.btn-danger {
    width: 98%;
    height: 49px;
    border-radius: 8px;
    font-family: Poppins;
    font-size: 1rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: 0.47px;
    text-align: center;
    color: #ffffff;
    background: linear-gradient(179deg, #f36e4c 0, #f05864 100%);
    border: none;
    box-shadow: none !important;
    display: block;
    margin: 0 auto;
    margin-bottom: 1rem;
}

p.coupons {
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #2c2c2c;
    margin-top: -17px;
}

/* Top Travel Stores
    =====================*/
.intro-grid a {
    min-height: 200px;
}

.intro-grid .grid-section-inner {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    padding: 0 15px;
    margin: 0 -15px;
    text-align: center;
    transform: scale(1);
    transform-origin: 50% 50%;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.intro-grid .grid-section-inner:hover {
    z-index: 2;
    background: #fff;
    transform: scale(1.1);
    box-shadow: 0px 0px 13px 3px rgba(0, 0, 0, 0.07);
}

.intro-grid h3 {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: bold;
}

.intro-grid img {
    max-width: 48px;
}

.intro-grid a:before,
.intro-grid a:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    right: -1px;
    border-right: 1px solid #eee;
}

.intro-grid a:after {
    width: 100%;
    height: 0;
    height: 0;
    top: auto;
    bottom: -1px;
    left: 0;
    right: auto;
    border-top: 1px solid #eee;
    border-right: 0;
}

/* my favorite stores page 24/9 */

.products .single-pro {
    margin-right: 12px;
    border-radius: 8px;
    border: solid 1px #e4e5e6;
    background-color: #ffffff;
    margin-bottom: 11px;
    padding: 10px 20px;
}

.products .row {
    margin: 0.5rem auto;
}

@media only screen and (max-width: 600px) {
    .col-md-5ths.col-xs-6.single-pro {
        margin: 10px auto;
        width: 45%;
    }
}

/* Privacy page
    ==================*/
.privacy li {
    list-style-position: inside;
}

/* About page
    ===============*/
.about h3,
.contact h3 {
    margin-bottom: 10px;
    margin-top: 25px;
}

.about .sub_title,
.contact .sub_title {
    color: #949698;
}

.about-img {
    margin-bottom: 30px;
    display: inline-block;
    position: relative;
}

.about-img-txt {
    position: absolute;
    top: 30%;
    padding-left: 5%;
    text-align: left;
    width: 48%;
}

.about-img-txt h4,
.about-img-txt h2 {
    color: #fff;
}

.about li {
    list-style: none;
    margin-bottom: 48px;
    font-weight: 500;
    padding-left: 60px;
    position: relative;
}

.about li:before {
    content: "\f00c";
    font-family: "Font Awesome 5 Pro";
    color: #f05864;
    background: rgba(240, 88, 100, 0.08);
    padding: 7px;
    border-radius: 50%;
    font-size: 14px;
    margin-right: 7px;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

@media only screen and (max-width: 736px) {
    .about-img-txt {
        top: 22%;
    }

    .about-img-txt h4 {
        margin-bottom: 10px;
    }

    .about-img-txt h2 {
        font-size: 15px;
        line-height: 1.5em;
    }
}

/* contact page
    ================*/
.contact select {
    background: url(../images/sign-in/dropdown.png) right center no-repeat;
}

/* help pages
    ===============*/
.help-img {
    margin-bottom: 30px;
    display: inline-block;
    position: relative;
}

.help-img img {
    border-radius: 12px;
}

/* .help-img-txt {
    position: absolute;
    top: 16%;
    padding-left: 5%;
    text-align: left;
    width: 48%;
  } */

.help-img-txt h2,
.help-img-txt h4 {
    color: #f26d4c;
}

@media only screen and (max-width: 736px) {
    /* .help-img-txt {
      top: 8%;
    } */

    .help-img-txt h4 {
        margin-bottom: 5px;
        font-size: 12px;
    }

    .help-img-txt h2 {
        font-size: 13px;
        line-height: 1.5em;
    }
}

.help-tabs .list-group-item.active {
    background-color: #fff;
    border-left: 2px solid #f05864;
    border-radius: 0;
}

section.help-tabs h3 {
    color: #1d1d1d;
    border-bottom: 1px solid #e4e5e6;
    padding-bottom: 1.5rem;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
}

section.help-tabs h2 {
    color: #f05864;
}

.help-tabs p {
    color: #1d1d1d;
}

.help-tabs .list-group-item {
    border-bottom: none;
}

.help-blocks h3 {
    color: #2c2c2c !important;
}

.help-single-block {
    display: flex;
    height: 225px;
    border-radius: 10px;
    background-color: #f8f8f8;
    padding: 40px 20px;
    margin-bottom: 25px;
}

.help-single-block i {
    align-self: flex-end;
    color: #fff;
    background-image: linear-gradient(90deg,
            rgba(239, 79, 112, 1) 7%,
            rgba(242, 109, 76, 1) 84%);
    padding: 10px 14px;
    border-radius: 50px;
    cursor: pointer;
}

.help-single-block:hover {
    background: linear-gradient(90deg,
            rgba(239, 79, 112, 1) 7%,
            rgba(242, 109, 76, 1) 84%);
    box-shadow: 3px 10px 22px -15px rgba(148, 145, 148, 1);
}

.help-single-block:hover h5 {
    color: #fff;
}

.help-single-block:hover i {
    background-color: #fff;
    background-image: none;
    color: #f26d4c;
}

.help-tabs .tab-content .faq-wrapper:not(:last-child) {
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid #dee2e6;
}

.help-tabs .tab-content .faq-wrapper h4 {
    margin-bottom: 1rem;
}

/* Refer & Earn Page
    ======================*/
.ref-earn-title h1 {
    font-family: Poppins;
    /*font-size: 62px;*/
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #2c2c2c;
    padding-top: 5%;
}

.ref-earn-title p {
    font-family: Poppins;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.55;
    letter-spacing: normal;
    text-align: center;
    color: #2c2c2c;
    width: 80%;
}

.main-icon {
    width: 135px;
    height: 135px;
    background-image: linear-gradient(155deg, #f0586450 24%, #f26d4c50 92%);
    border-radius: 5em;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-icon i {
    width: 96px;
    height: 96px;
    box-shadow: 0 26px 36px 0 #bc2f3a;
    background-image: linear-gradient(157deg, #f26d4c 22%, #f05864 96%);
    border-radius: 5em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-icon i::before {
    font-size: 35px;
    color: #ffffff;
}

.refer-steps .icons h1 {
    font-size: 22px;
    font-weight: 600;
    color: #2c2c2c;
    margin-top: 1rem;
}

.refer-steps .icons p {
    opacity: 0.8;
    font-size: 15px;
    text-align: center;
    color: #1d1d1d;
    margin-top: -0.7rem;
}

.col.arrow-icon-line {
    margin: 4.5rem auto;
    width: 3.8rem !important;
}

.arrow-icon-line:after {
    content: "";
    display: block;
    width: 7rem;
    height: 2px;
    background: #e4e5e6;
    position: relative;
    top: 0.8rem;
    right: 0rem;
    z-index: -1;
}

.arrow-icon-line .fa-chevron-right::before {
    width: 15px;
    height: 11px;
    font-size: 12px;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #2c2c2c;
    position: relative;
    top: 0.9rem;
    left: 1.3rem;
}

.arrow-icon-line .fa-chevron-right {
    width: 48px;
    height: 48px;
    background-color: #e4e5e6;
    border-radius: 50%;
    position: absolute;
    left: 3.2rem;
    bottom: 6rem;
}

@media (max-width: 991px) {
    .main-icon {
        width: 85px;
        height: 85px;
    }

    .main-icon i {
        width: 56px;
        height: 56px;
    }

    .main-icon i::before {
        font-size: 20px;
    }
}

/* share referral link section */
.referral-link {
    background-image: url(../images/refer/gray-bg.png);
    border-radius: 19px;
}

.referral-link h1 {
    font-weight: 600;
    text-align: center;
    color: #2c2c2c;
}

.referral-details {
    width: 80%;
    margin: 0 auto;
}

.copy-link {
    border-radius: 10px;
    border: solid 1px rgba(206, 207, 208, 0.21);
    background-color: #ffffff;
    position: relative;
    padding: 10px;
    padding-right: 200px;
    min-height: 82px;
    display: flex;
    align-items: center;
}

.copy-link .main-btn {
    position: absolute;
    right: 10px;
}

.copy-link input[type="button"] {
    font-size: 17px;
    color: #2c2c2c;
    border: none;
    background-color: transparent;
}

h2.share-via {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    color: #2c2c2c;
}

button.facebook {
    width: 236px;
    height: 61px;
    border-radius: 8px;
    background-image: linear-gradient(99deg, #3d48c6 9%, #4380e2 89%);
    border: none;
    margin: auto;
    display: block;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-align: center;
    color: #ffffff;
}

.activity {
    color: #fff;
}

.activity .activ-box {
    background-image: url(../images/refer/activity-bg.png);
    background-position: center;
    border-radius: 10px;
    color: #fff;
    padding: 15px;
}

.activity .activ-box .box-upper .main-icon {
    background-image: linear-gradient(155deg, #ffffff50 24%, #ffffff50 92%);
    width: 74px;
    height: 74px;
}

.activity .activ-box .box-upper .main-icon i {
    width: 54px;
    height: 54px;
    background-image: linear-gradient(157deg, #fff 22%, #fff 96%);
}

.activity .activ-box .box-upper .main-icon i::before {
    font-size: 20px;
    color: #f05864;
}

@media (max-width: 991px) {
    .copy-link {
        border-radius: 10px;
        padding: 10px;
        padding-right: 10px;
        min-height: 82px;
        flex-direction: column;
    }

    .copy-link .main-btn {
        right: 10px;
        font-size: 12px;
        padding: 10px 15px;
        position: static;
    }

    .copy-link input[type="button"] {
        font-size: 17px;
    }
}

/* Start how refer a friend works style */
section.refer-friend {
    margin-top: 4rem !important;
    /*min-height: 555px;*/
    background-color: #f8f8f8;
}

.refer-friend .row {
    padding-left: 6rem;
}

.refer-friend button.btn-danger {
    width: 170px;
    height: 47px;
    border: none;
    outline: none;
    border-radius: 30px;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    position: absolute;
    left: -85px;
    top: 2rem;
}

.refer-friend .icon {
    background-image: linear-gradient(155deg, #00000014 24%, #dbdbdb21 92%);
    border-radius: 50%;
    width: 6.5rem;
    height: 6.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.refer-friend .icon i {
    background: #a2a2a2;
    border-radius: 50%;
    width: 4.5rem;
    height: 4.5rem;
    box-shadow: 0 26px 36px 0 rgba(0, 0, 0, 0.35);
    font-size: 27px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-i:before {}

.text-content {
    /* border-left: solid 1px #e4e5e6; */
}

.text-content h2 {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #2c2c2c;
    margin-top: 2rem;
}

.text-content p {
    opacity: 0.8;
    font-size: 14px;
    text-align: center;
    color: #1d1d1d;
    padding: 0 1rem;
}

@media (max-width: 991px) {
    .refer-friend .icon {
        width: 85px;
        height: 85px;
    }

    .refer-friend .icon i {
        width: 56px;
        height: 56px;
    }

    .refer-friend .icon i::before {
        font-size: 20px;
    }
}

/* start the refer term and condition style */
section.condition {}

.condition h1 {
    font-weight: 600;
    text-align: center;
    color: #2c2c2c;
    margin-bottom: 1rem;
}

.condition p.first-content {
    font-size: 15px;
    text-align: left;
    color: #1d1d1d;
}

@media (max-width: 767.98px) {
    .refer-friend button.btn-danger {
        position: static;
        margin-bottom: 2rem;
    }

    .refer-friend .row {
        padding-left: 0;
    }
}

/* media query the ## mobile version ## */
@media only screen and (max-width: 1100px) {
    .col.arrow-icon-line {
        display: none;
    }

    .col-lg-3.col-md-4.col-sm-12 {
        min-width: 33.3% !important;
    }

    .copy-link {
        text-align: center;
    }

    .copy-link input[type="button"] {
        width: 96%;
        height: 32px;
        font-size: 16px;
        text-align: center;
    }

    .copy-link input[type="button"] {
        width: 96%;
        height: 32px;
        font-size: 16px;
        text-align: center;
    }

}


@media (min-width: 1084px) and (max-width: 1200px) {
    .arrow-icon-line .fa-chevron-right {
        bottom: 7rem !important;
    }
}

/* Profile Pages
    ===================*/
.profile-sidebar {
    border-radius: 8px;
    margin-top: -9rem;
    border: 1px solid #dedede;
}

.bg-gray-section {
    height: 195px;
    object-fit: contain;
    background-image: url(../images/profile/bg-gray.png);
}

.profile-image {
    width: 100%;
    background-image: url(../images/profile/profile-image-bg.png);
    border-bottom: none;
    background-size: cover;
    padding: 48px 0;
}

img.ar-camera {
    position: relative;
    top: -2.8rem;
    left: 12rem;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.09);
    background-color: #ffffff;
    padding: 0.5rem;
    border-radius: 48%;
}

.user-status img.profile-img {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.07);
    background-color: #f9f9f9;
    display: block;
    margin: 0 auto;
    /*padding: 1rem 1rem 0 1rem;*/
    border-radius: 5em;
    max-width: 90px;
}

h1.user-name {
    font-size: 29px;
    font-weight: 600;
    text-align: center;
    color: #2c2c2c;
    margin-top: 0rem;
}

p.user-date {
    font-size: 16px;
    text-align: center;
    color: rgba(44, 44, 44, 0.5);
}

.life-time-earn {
    width: 100%;
    background: url(../images/profile/life-time-bg.png);
    background-size: cover;
    border-radius: 9px;
    margin: 0 auto;
    padding: 37px 30px;
}

p.life-time-p,
.balance-section p {
    width: 60%;
    display: block;
    font-size: 18px;
    font-weight: normal;
    color: #ffffff;
}

.life-time-money {
    font-size: 27px;
    font-weight: 600;
    color: #ffffff;
}

.balance-section {
    padding: 37px 30px;
}

.balance-section p {
    font-size: 15px;
    color: #1d1d1d;
    margin-bottom: 0.5rem;
}

.balance-section span {
    font-size: 17px;
    font-weight: 500;
    color: #1d1d1d;
    margin-bottom: 0.5rem;
}

.balance-section i.fas.fa-info:before {
    width: 16px;
    height: 16px;
    font-size: 11px;
    color: #2c2c2c;
    border: 1px solid;
    padding: 2px 5px;
    border-radius: 25px;
}

@media (max-width: 991px) {
    .profile-sidebar {
        margin-top: 4rem;
    }
}

/* start vertical tabs style */

.vertical-items .navbar-nav>.nav-item>.nav-link {
    font-size: 15px;
    color: #1d1d1d !important;
    padding: 0;
    margin: 0;
}

.vertical-items .navbar-nav>.nav-item {
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
    position: relative;
    margin-left: 0;
}

.vertical-items {}

/*
        ##########################################
        #######                             ######
        #####    start cashback over view ########
        ####                            ##########
        ##########################################
        */

h1.overview {
    font-size: 25px;
    font-weight: 600;
    color: #2c2c2c;
}

.overview i.fas.fa-poll-h:before {
    font-size: 22px;
    text-align: center;
    color: #2c2c2c;
}

.overview i.fas.fa-poll-h {
    background-color: #f8f8f8;
    padding: 8px 12px;
    border-radius: 50%;
}

.line-under-title {
    width: 100%;
    height: 0;
    border: solid 1px #e4e5e6;
}

.overview-tabs div#myBtnContainer {
    background-color: #fff;
    border-bottom: 2px solid #dedede;
    border-radius: initial;
    padding-bottom: 1rem;
}

.cashback-activity select.form-control {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: rgba(44, 44, 44, 0.8);
    height: 56.4px;
    background: url(../images/sign-in/dropdown.png) 120px 22px no-repeat !important;
}

.cashback-activity button.btn-danger {
    width: 169px !important;
    height: 56px !important;
    border-radius: 8px;
    outline: none;
    border: none;
    font-size: 16px;
}

.table .custom-table {
    width: 105% !important;
}

.cashback-activity td,
.withdrawal td,
.click-activity td {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.67;
    letter-spacing: normal;
    text-align: left;
    color: #1d1d1d;
}

span.time-span,
span.number-span {
    opacity: 0.6;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1d1d1d;
    display: block;
    margin-top: -0.8rem;
}

td.paid-color {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.82;
    letter-spacing: normal;
    text-align: left;
    color: #2ad1a4;
}

td.pending-color {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.82;
    letter-spacing: normal;
    text-align: left;
    color: #f05864 !important;
}

.cashback-activity table .custom-table th,
.withdrawal table .custom-table th,
.click-activity table .custom-table th {
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 3;
    letter-spacing: normal;
    text-align: left;
    color: #1d1d1d;
}

.cashback-activity #myBtnContainer .btn:focus {
    border: none !important;
    outline: none !important;
}

.casback-activity .table th,
.cashback-activity .table td {
    padding: 3px !important;
}

/*
        ##########################################
        #######                             ######
        #####    start media query        ########
        ####                            ##########
        ##########################################
    */

@media (min-width: 1023px) and (max-width: 1430px) {
    .click-activity .content {
        width: 143%;
    }

    .click-activity select {
        background: url(../images/sign-in/dropdown.png) 140px 13px no-repeat;
    }

    .casback-activity .table th,
    .cashback-activity .table td {
        padding: 0px !important;
    }
}

@media (min-width: 720) and (max-width: 970) {
    .cashback-activity select.form-control {
        width: 95% !important;
        display: block !important;
    }

    .cashback-activity button.btn-danger {
        width: 195px !important;
    }

    .cashback-activity table .custom-table th,
    .withdrawal table .custom-table th,
    .click-activity table .custom-table th {
        font-size: 11px;
        line-height: normal;
        padding: 0.5rem;
    }

    span.time-span,
    span.number-span {
        font-size: 10px;
    }

    .cashback-activity td,
    .withdrawal td,
    .click-activity td {
        font-size: 0.7rem !important;
        font-weight: normal !important;
        padding: 0rem 1.5rem;
    }

    img.ar-camera {
        left: 11.5rem !important;
    }

    h1.overview {
        font-size: 26px;
    }

    .casback-activity .table th,
    .cashback-activity .table td {
        padding: 0px !important;
    }
}

@media (min-width: 420) and (max-width: 585) {

    .cashback-activity table .custom-table th,
    .withdrawal table .custom-table th,
    .click-activity table .custom-table th {
        font-size: 14px;
    }

    .cashback-activity td,
    .withdrawal td,
    .click-activity td {
        font-size: 14px;
        font-weight: 500;
    }

    span.time-span,
    span.number-span {
        margin-top: 0.2rem;
    }

    img.ar-camera {
        left: 14rem;
        border-radius: 45%;
    }

    .casback-activity .table th,
    .cashback-activity .table td {
        padding: 0px !important;
    }
}

@media (min-width: 420px) and (max-width: 1196px) and (orientation: landscape) {
    .balance-section p {
        font-size: 14px !important;
    }

    .table .custom-table {
        max-width: 105% !important;
    }

    .cashback-activity select.form-control {
        width: 104% !important;
        display: block !important;
    }

    .cashback-activity td,
    .withdrawal td,
    .click-activity td {
        font-size: 1rem !important;
        font-weight: normal !important;
    }

    .cashback-activity table .custom-table th,
    .withdrawal table .custom-table th,
    .click-activity table .custom-table th {
        font-size: 14px;
    }

    .life-time-earn {
        padding: 0rem 1rem;
        margin-top: -3rem;
        height: 120px;
    }

    p.life-time-p.mt-4 {
        margin: 20px 5px !important;
        width: inherit;
        text-align: center;
    }

    .balance-section p {
        width: inherit;
    }

    .life-time-money {
        text-align: center;
    }

    .casback-activity .table th,
    .cashback-activity .table td {
        padding: 3px !important;
    }
}

@media (min-width: 320px) and (max-width: 419px) {
    .balance-section p {
        font-size: 12px;
        font-weight: normal;
        line-height: 4;
    }

    .casback-activity .custom-table th,
    .cashback-activity .custom-table td {
        padding: 0px !important;
    }

    .thead-light th {
        font-size: 11px;
        padding: 4px 10px;
    }

    .cashback-activity td,
    .withdrawal td,
    .click-activity td {
        font-size: 14px;
        font-weight: 500;
    }

    img.ar-camera {
        left: 13rem;
    }
}

@media (max-width: 535px) {
    .custom-table thead.thead-light {
        display: none;
    }

    .custom-table,
    .custom-table tbody,
    .custom-table tr,
    .custom-table td {
        display: block;
        width: 100%;
    }

    .custom-table tr {
        margin-bottom: 10px;
    }

    .custom-table tr {
        text-align: right;
        padding-left: 50%;
        position: relative;
    }

    .custom-table td:before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 10px;
    }
}

@media (max-width: 768) and (min-width: 533) {
    img.ar-camera {
        left: 22rem !important;
    }
}

@media (max-width: 767) and (min-width: 419) {
    img.ar-camera {
        left: 15.5rem;
    }
}

@media (min-width: 419) and (max-width: 500) {
    img.ar-camera {
        left: 58%;
    }
}



/* profile - favorite stores
    ===============================*/
section.fav-stores.mt-2 .products .single-pro {
    margin-right: 0;
    margin-top: 0.75rem;
}

section.fav-stores.mt-2 .products .single-pro i.far.fa-star {
    font-size: 18px;
    color: #f05864;
    background-color: rgba(240, 88, 100, 0.1);
    padding: 6px;
    border-radius: 50%;
}

/* profile - update password
    ===============================*/
.profile-password form .form-control {
    color: rgba(44, 44, 44, 0.8);
    border-radius: 6px;
    border: solid 1px #e8e8e8;
    padding-left: 24px;
    height: 60px;
    padding: 15px;
    margin: 5px 0 22px 0;
    display: inline-block;
    border: solid 1px #e8e8e8;
    font-size: 14px;
}

.profile-password form label {
    color: #2c2c2c;
    font-size: 16px;
    font-weight: normal;
    opacity: 0.8;
    cursor: unset;
    display: block;
    text-transform: capitalize;
    letter-spacing: initial;
}

/* profile - Account information
    ==================================*/

.vertical-items li.nav-item:after {
    width: 2px;
    height: 100%;
    background-color: #f05864;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: none;
}

.vertical-items li.nav-item.active:after {
    display: block;
    /* border-left: solid; */
}

.vertical-items .navbar-nav>.nav-item.active>.nav-link {
    color: #f05864 !important;
}

h1.overview i.far.fa-user {
    border: solid 2px;
    padding: 4px 6px;
    border-radius: 50%;
}

/* Profile withdraw
    ===================*/
.withdraw-page #myBtnContainer {
    border-radius: 0px;
    padding: 0px 0px;
    background: #fff;
    border-bottom: 2px solid #dedede;
    padding-bottom: 5px;
}

.withdraw-page #myBtnContainer .btn i:before {
    padding: 10px;
}

.withdraw-page #myBtnContainer .btn.active {
    outline: none !important;
    border: none !important;
}

.withdraw-page #myBtnContainer .btn.active:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

.withdraw-page #myBtnContainer .btn.active {
    box-shadow: none !important;
}

button.btn.me-3.wallet-button.active {
    padding-bottom: 0.3rem !important;
}

.withdraw-page #myBtnContainer .btn.active i:before {
    /* padding: 10px; */
    background: #f05864 !important;
    color: #fff;
    border-radius: 8px;
}

button.btn.me-3.active.active:before {
    content: "";
    position: relative;
    width: 107%;
    height: 3px;
    background: #f05864;
    top: 3rem;
    display: block;
}

button.btn.me-3.wallet-button.active.active:before {
    top: 3.3rem;
}

.bank-transfer .tobank,
.wallet-transfer .tobank {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: normal;
    text-align: left;
    color: #2c2c2c;
}

.bank-transfer .trasferparagraph,
.wallet-transfer .trasferparagraph {
    opacity: 0.7;
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 4.27;
    letter-spacing: normal;
    text-align: left;
    color: #2c2c2c;
    margin-top: -2rem;
}

.bank-transfer .withdraw-page,
.wallet-transfer .withdraw-page {
    opacity: 0.8;
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: #2c2c2c;
}

.bank-transfer p.withdraw-amount.mt-5,
.wallet-transfer p.withdraw-amount.mt-5 {
    opacity: 0.8;
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: #2c2c2c;
    margin-bottom: 0px;
}

.bank-transfer .dollar-amount,
.wallet-transfer .dollar-amount {
    width: 20rem;
    height: 60px;
    border-radius: 6px;
    border: solid 1px #e8e8e8;
}

.bank-transfer button.btn.add-bank.mt-5.mb-3,
.wallet-transfer button.btn.add-bank.mt-5.mb-3 {
    width: 397px;
    height: 156.7px;
    border-radius: 6px;
    border: dotted 2px #c7c7c7;
}

.paypal-box {
    width: 397px;
    height: 156.7px;
    border-radius: 6px;
    background: url(../images/profile/bg-gray.png);
}

.bank-transfer .dollar-number,
.wallet-transfer .dollar-number {
    width: 25rem;
    height: 3rem;
    text-align: left;
    padding-left: 2rem;
    color: #4e4e4e;
    border-radius: 6px;
    border: 1px solid #e8e8e8;
}

.withdraw-money-tabs .content {
    border-bottom: none;
}

.bank-transfer .withdraw-grey-button,
.wallet-transfer .withdraw-grey-button {
    display: block;
    width: 225px;
    height: 61px;
    border-radius: 8px;
    background-color: #e4e5e6;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

#myBtnContainer .withdraw-money-tabs i {
    color: #212529 !important;
    background: #dedede66;
    border-radius: 20px;
    line-height: 2.5rem !important;
}

@media (max-width: 991px) {
    .vertical-items .navbar-nav {
        flex-direction: row;
        text-align: center;
        flex-wrap: wrap;
        justify-content: center;
    }

    .vertical-items .navbar-nav>.nav-item>.nav-link {
        display: flex;
        flex-direction: column;
    }

    .vertical-items .navbar-nav>.nav-item>.nav-link i {
        margin-bottom: 1rem;
        font-size: 1.5rem;
    }

    .vertical-items li.nav-item:after {
        width: 100%;
        height: 2px;
        right: 0;
        bottom: 0;
        top: unset;
    }

    .vertical-items .navbar-nav>.nav-item {
        margin: 10px !important;
        padding: 10px 0;
        width: auto;
    }

    .main-tabs-col .nav-tabs .nav-item {
        width: auto;
        padding: 0;
    }
}

/* pop up form */
.form-popup {
    display: none;
    width: 100%;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    transition: 0.5s;
    overflow: scroll;
}

.form-popup .form-wrapper {
    top: 2em;
    max-width: 700px;
    background: white;
    padding: 50px;
    padding-top: 50px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    border: 1px solid #000;
    border-radius: 5px;
    margin-bottom: 15px;
}

.close-form {
    border-radius: 0px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9;
    cursor: pointer;
    padding: 10px 30px;
    background-color: none;
    border: none;
    color: #000;
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 20px;
    transition: 0.3s;
}

p.form-label {
    margin-bottom: 5px;
    opacity: 0.8;
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: #2c2c2c;
}

.form-popup select.form-control {
    width: 100%;
    height: 60px;
    border-radius: 6px;
    border: solid 1px #e8e8e8;
    background: url(../images/sign-in/dropdown.png) 28rem no-repeat;
    background-color: #f8f8f8;
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: rgba(44, 44, 44, 0.8);
}

.add-bank-form div {
    margin: 2rem auto;
}

.add-bank-form input {
    width: 100%;
    height: 60px;
    border-radius: 6px;
    border: solid 1px #e8e8e8;
    background-color: #f8f8f8;
    padding-left: 15px;
}

.container.form-wrapper::-webkit-scrollbar {
    display: none;
}

.form-popup::-webkit-scrollbar {
    display: none;
}

.save-btn button.btn.btn-danger {
    width: 15rem;
    height: 61px;
    border-radius: 8px;
    display: block;
    margin: 12px auto;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.add-bank-bg h1.title {
    font-family: Poppins;
    font-size: 26px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: #2c2c2c;
}

.add-bank-bg {
    background-image: url(../images/home/bg-3.png);
    padding: 2rem 0rem;
}

.credit-icon {
    width: 116px;
    height: 116px;
    background-image: linear-gradient(155deg, #f0586412 24%, #f26d4c14 92%);
    z-index: -1;
    margin: 1rem auto;
    border-radius: 100%;
}

.credit-icon i.fas.fa-credit-card {
    width: 30px;
    height: 27px;
    font-size: 27px;
    line-height: 1.26;
    letter-spacing: normal;
    color: #ffffff;
    padding: 30px;
    border-radius: 50px;
    position: relative;
    top: 2rem;
    left: 1.7rem;
    background-image: linear-gradient(157deg, #f26d4c 22%, #f05864 96%);
}

.credit-icon i.fas.fa-credit-card:before {
    top: 1rem;
    position: absolute;
    left: 1rem;
}

.unverified-p {
    font-family: Poppins;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: #2c2c2c;
}

.unverified-click {
    color: #f05864;
}

.paypal-unverified {
    height: 25px;
    background-color: #ffd4d7;
    border-radius: 10px 10px 0px 0px;
    padding-left: 1rem;
    padding-top: 5px;
}

.paypal-content {
    margin-top: 3rem;
    height: 193px;
    background: url(../images/home/bg-3.png);
    padding: 0px;
    border-radius: 10px;
}

img.paypal-img {
    display: block;
    padding-left: 2rem;
}

.paypal-content h1.name {
    margin-top: 1rem;
    text-align: left;
    padding-left: 2rem;
}

.paypal-content p.user-email {
    font-size: 14px;
    padding-left: 2rem;
    margin-top: -1.5rem;
}

/* Top travel stores
    ======================= */

.all-categories {
    height: 100%;
    border-radius: 10px;
    border: solid 1px #e4e5e6;
}

.categories_title {
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: normal;
    text-align: left;
    color: #2c2c2c;
    border-bottom: 1px solid #e4e5e6;
    padding: 10px;
}

.categories_links {
    padding: 20px;
    line-height: 38px;
}

.categories_links ul li {
    list-style: none;
}

.categories_links ul li a {
    color: #1d1d1d;
}

.categories_links ul li a:hover {
    color: #f05864;
}

.active_tab:before {
    content: "";
    display: inline-block;
    position: relative;
    top: 5px;
    left: -10px;
    border-left: 2px solid #f05864;
    height: 20px;
}

#show_cat {
    margin-left: 20px;
}

#sort_by {
    float: right;
}

#form-title {
    margin-top: 5px;
    margin-right: 10px;
}

#pagination {
    margin-top: 30px;
}

.top_travel_store {
    display: flex;
}

.store_brand h3,
.travel_stores h3 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #1d1d1d;
    margin-bottom: 10px;
}

.store_brand p,
.travel_stores p {
    color: #f05864;
    font-weight: 600;
    text-align: center;
}

.store_brand {
    border-radius: 8px;
    background-color: #f7f7f7;
    margin-bottom: 11px;
    padding: 10px 17px;
}

.flex-container>div {
    margin: 8px;
}

.flex-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.travel_stores {
    margin-right: 12px;
    border-radius: 8px;
    background-color: #f7f7f7;
    margin-bottom: 11px;
    padding: 10px 17px;
}

.travel_stores {
    margin-right: 10px;
    background-color: transparent;
    width: 25%;
}

.page-link {
    background: none !important;
    color: #999595 !important;
    border: none;
    width: 27px !important;
    height: 27px !important;
    line-height: 26px !important;
    text-transform: uppercase;
}

.pagination.pagination-primary .page-item.active .page-link,
.pagination.pagination-primary .page-link:hover {
    background-color: #f05864 !important;
}

.travel_stores_section div {
    border: 1px solid #eee;
    border-radius: 0;
    margin: 0;
}

.travel_stores_section {
    position: relative;
}

.travel_stores_section .image {
    border: none;
}

.pagination.pagination-circle .page-item:not(:first-child) .page-link,
.pagination.pagination-rounded .page-item:not(:first-child) .page-link {
    margin-left: 3px !important;
}

@media only screen and (min-width: 320px) and (max-width: 480px) {

    .travel_stores,
    .store_brand {
        width: 100%;
    }
}

/* redirect page
    ======================*/
#redirect-to-link.container-fluid {
    padding-left: 0;
    padding-right: 0;
}

.top-redirect {
    height: 50vh;
    background-image: url(../images/home/footer.png);
}

.content-box {
    background: #fff;
    padding: 30px;
    width: 40%;
    text-align: center;
    border-radius: 14px;
    box-shadow: 0 43px 99px 0 rgba(0, 0, 0, 0.08);
    display: block;
    margin: 0 auto;
    margin-top: -10%;
}

.content-box h4 {
    padding-top: 20px;
}

.content-box .red,
.bottom-redirect a {
    font-weight: 600;
    color: #f05864;
}

.bottom-redirect p {
    text-align: center;
    margin-top: 15px;
}

@media only screen and (max-width: 992px) {
    .content-box {
        width: 85%;
        margin-top: -65%;
    }
}

/* How to earn cashback
    =========================*/

.earn-banner {
    position: relative;
}

.earn-banner .earn-content {
    background-image: url(../images/how-to-earn/how-to-earn-banner.jpg);
    background-position: center;
    border-radius: 0.5rem;
    color: #fff;
    text-align: center;
}

.earn-banner h1 {
    color: #fff;
}

.refer-steps .icons h4 {
    font-size: 20px;
    color: #1d1d1d;
    margin-top: 2rem;
    margin-bottom: 0;
    font-weight: normal;
}

.col.arrow-icon-line22 {
    margin: 4.5rem auto;
    width: 3.8rem !important;
    position: relative;
}

.col.arrow-icon-line22.light-arrow {
    margin: 4.5rem auto;
    width: 3.8rem !important;
    position: relative;
}

.arrow-icon-line22.light-arrow .fa-chevron-right {
    background-color: #e4e5e6;
    bottom: 7.3rem;
}

.arrow-icon-line22.light-arrow:after {
    background: #e4e5e6;
}

.arrow-icon-line22 .fa-chevron-right {
    width: 48px;
    height: 48px;
    background-color: #f05864;
    border-radius: 50%;
    position: absolute;
    left: 0;
    bottom: 2.3rem;
    right: 0;
    margin: auto;
}

.arrow-icon-line22 .fa-chevron-right::before {
    width: 15px;
    height: 11px;
    font-size: 12px;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    position: relative;
    top: 0.9rem;
    left: 1.3rem;
}

.arrow-icon-line22:after {
    content: "";
    display: block;
    height: 2px;
    background: #f05864;
    position: relative;
    top: 0.8rem;
    right: 0rem;
    z-index: -1;
}

@media only screen and (max-width: 992px) {
    .earn-banner h1 {
        margin-bottom: 5px;
    }



    .arrow-icon-line22 {
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .earn-banner h1 {
        margin-bottom: 5px;
    }


}

/* Steps section
    =================*/
.mega-menu-content.mega-menu-style-2.how-to-earn {
    width: 100% !important;
}

.steps-section button.btn.btn-danger {
    width: 195px;
    height: 53px;
    margin-bottom: 20px;
}

section.steps-section .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(240, 88, 100, 0.09);
    width: 60px;
    height: 40px;
    border-radius: 5em;
    color: #f05864;
    font-size: 18px;
    vertical-align: top;
}

section.steps-section p {
    font-size: 13px;
    font-weight: 500;
}

section.steps-section .separte-arrow i {
    background: #f8f8f8;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: #2c2c2c;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

section.steps-section .separte-arrow i:before {
    vertical-align: -webkit-baseline-middle;
}

section.steps-section .icon i.fas.fa-wallet,
section.steps-section .icon i.icon-money {
    /* line-height: 2em; */
}

@media only screen and (max-width: 600px) {
    section.steps-section .separte-arrow i {
        display: none;
    }
}

/* Unverified pages
    ====================*/
#modal-unverified,
#modal-verified,
#modal-password-updated {
    text-align: center;
}

#modal-unverified h4,
#modal-verified h4,
#modal-password-updated h4 {
    font-size: 30px;
}

section.unverified,
.unverified-account-toast {
    background: #f9e8e3;
    margin-bottom: 5px;
}

section.unverified p,
.unverified-account-toast p {
    margin: 5px;
    text-align: center;
    color: #2c2c2c;
}

section.unverified a,
.unverified-account-toast a {
    color: #f26d4c;
    text-decoration: underline !important;
}

.unverified-header-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 99px;
    height: 99px;
    box-shadow: 0 26px 36px 0 rgba(188, 47, 58, 0.44);
    background: linear-gradient(157deg, #f26d4c 22%, #f05864 96%);
    border-radius: 50%;
    margin: 3em auto;
}

#modal-verification .modal-card-body {
    display: flex;
    justify-content: center;
}

#modal-verification form {
    margin-bottom: 0;
}

#modal-verification .modal-card-body input {
    width: 64px;
    margin: 10px 5px;
}

.verified-header-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 137px;
    height: 137px;
    box-shadow: 0 26px 36px 0 rgba(14, 139, 31, 0.44);
    background-color: #27b118;
    border-radius: 50%;
    margin: 3em auto;
}

/* system pages
    =================*/
#not-found,
#server-down,
#under-maintenance {
    text-align: center;
    margin: 2em auto;
}

#not-found button,
#server-down button {
    width: 210px;
    margin-bottom: 20px;
}

#not-found h2,
#server-down h2,
#under-maintenance h2 {
    font-family: Poppins;
    font-size: 2.5em;
    font-weight: 600;
    color: #2c2c2c;
    margin-top: 30px;
}

#not-found p,
#server-down p,
#under-maintenance p {
    font-family: Poppins;
    font-size: 1.4em;
    color: #949698;
}

#under-maintenance input {
    padding: 15px;
    border: solid 1px #e8e8e8;
    background-color: #f8f8f8;
    font-size: 14px;
    border-radius: 6px;
    width: 35%;
}

#under-maintenance button {
    width: 195px;
}

/* Profile Information
    ==========================*/
.profile-information input[type="tel"] {
    padding-left: 90px;
}

.profile-information input {
    width: 100%;
    padding: 15px;
    margin: 5px 0 22px 0;
    display: inline-block;
    border: solid 1px #e8e8e8;
    font-size: 14px;
    border-radius: 6px;
    height: 40px;
}

.profile-information .btn-group-toggle input {
    display: none;
}

.profile-information select {
    font-size: 16px;
}

.profile-information button {
    /* width: 210px !important; */
}

.profile-information .input-group-prepend {
    margin-top: -1px;
}

.profile-information label {
    display: block;
    font-size: 16px;
    text-transform: capitalize;
    color: #2c2c2c;
}

.profile-information select.selectpicker {
    display: block;
    border: solid 1px #e8e8e8;
    border-radius: 6px;
    width: 100%;
    height: 40px;
    background: url(../images/sign-in/dropdown.png) right 10px no-repeat !important;
}

/* Validation page
    ======================*/
.validation label,
.validation p.invalid-password,
.validation p.empty-password,
.validation p.invalid,
.validation p.incorrect-code {
    color: #ef0013;
    opacity: 0.8;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 14px;
}

input.form-control.invalid,
input.form-control.invalid-password,
input.form-control.empty-password {
    border-radius: 6px;
    border: solid 1px #ef0013;
    background-color: #f8f8f8;
}

input.unverified {
    width: 64px;
    height: 60px;
    margin: 10px 5px;
    border-radius: 6px;
    text-align: center;
    color: #ef0013;
    border: solid 1px #ef0013;
    background-color: #f8f8f8;
    display: initial;
}

select.selectpicker.unselected {
    border: solid 1px #ef0013;
    border-radius: 6px;
    width: 100%;
    height: auto;
}

/* start edit 14/2 */
.custom-cog {
    left: 18rem;
    bottom: 0rem;
    position: relative;
    cursor: pointer;
}

.custom-cog::before {
    color: #bfbdbd;
}

.edit-box a {
    display: block;
    margin-top: 0.5rem;
    margin-left: 0.7rem;
}

.edit-box {
    display: none;
    position: absolute;
    top: 3.9rem;
    min-height: 5rem;
    min-width: 10rem;
    background: #fff;
    text-align: left;
    left: 9rem;
    box-shadow: 0 0 4px 4px #c7c4c4b3;
}

.edit-box a {
    display: block;
    margin-top: 0.5rem;
    color: #636363;
    margin-left: 1.3rem;
    margin-bottom: 0.5rem;
}

@media only screen and (max-width: 420px) {
    .custom-cog {
        left: 17rem;
        bottom: 0rem;
    }

    .edit-box {
        top: 4rem;
        left: 7.2rem;
    }

    .bank-transfer button.btn.add-bank.mt-5.mb-3,
    .wallet-transfer button.btn.add-bank.mt-5.mb-3 {
        width: 280px;
    }

    input.btn.mt-3.mb-3.dollar-number {
        width: 17rem;
    }

    .paypal-content {
        width: 100%;
        margin: inherit;
    }
}

@media (min-width: 421px) and (max-width: 767px) {
    .custom-cog {
        left: 24rem;
        bottom: -1rem;
    }

    .edit-box {
        top: 4.8rem;
        left: 14rem;
    }
}

/* start edit 14/2 */

/*=========== Edits By Yousef 7/4/2022 ============*/

/*** Common Styles ***/
.sb-group {
    justify-content: center;
}

.main-header-title {
    font-family: Poppins;
    font-size: 28px;
    font-weight: 600;
    line-height: 1.5;
    color: #2c2c2c;
}

.second-header-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.46;
    color: #2c2c2c;
}

.main-btn {
    background: #f0536c;
    background: linear-gradient(135deg, #f0536c 21%, #f37246 104%);
    transition: 0.25s all ease-in-out;
    padding: 19px 50px 19px 50px;
    border-radius: 8px;
    font-weight: 600;
    border: unset;
    color: #fff;
    display: inline-block;
}

.main-btn:hover,
.main-btn:focus {
    -webkit-animation: pulse 1s infinite;
    animation: pulse 1s infinite;
    box-shadow: 0 0 0 0.5em transparent;
    color: #fff;
}

@-webkit-keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 #f0536c;
    }
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 #f0536c;
    }
}

.main-btn:focus-visible,
.second-btn:focus-visible {
    outline: none;
}

.second-btn {
    background: #e4e4e5;
    color: #1d1d1d;
    transition: 0.25s all ease-in-out;
    padding: 19px 50px 19px 50px;
    border-radius: 8px;
    font-weight: 500;
    border: unset;
    display: inline-block;
}

.second-btn:hover,
.second-btn:focus {
    -webkit-animation: second-pulse 1s infinite;
    animation: second-pulse 1s infinite;
    box-shadow: 0 0 0 0.5em transparent;
}

@-webkit-keyframes second-pulse {
    0% {
        box-shadow: 0 0 0 0 #e4e4e5;
    }
}

@keyframes second-pulse {
    0% {
        box-shadow: 0 0 0 0 #e4e4e5;
    }
}

.section-gap {
    padding-top: 70px;
    padding-bottom: 70px;
}

.title-wrapper .title {
    font-size: 42px;
    font-weight: 600;
    color: #2c2c2c;
    margin-bottom: 12px;
}

.title-wrapper .sub_title {
    color: #949698;
}

.store-sidebar-widget {
    border-radius: 10px;
    border: solid 1px #e4e5e6;
    padding: 20px 15px;
}

.store-sidebar-widget {
    margin-bottom: 27px;
}

.store-sidebar-widget .widget-title {
    font-size: 18px;
    font-weight: 600;
    color: #2c2c2c;
}

.store-sidebar-widget .widget-heading i {
    font-size: 20px;
}

.font-14 {
    font-size: 14px;
}

@media (max-width: 991px) {
    .section-gap {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

/*** Top Selling colors ***/
.slick-current .orange {
    border: solid 1px #f37246;
}

.orange .slider-link {
    background-color: #f3724640;
}

.slick-current .orange .store-category {
    color: #f37246;
}

.slick-current .purple {
    border: solid 1px #c42cfc;
}

.purple .slider-link {
    background-color: #c42cfc40;
}

.slick-current .purple .store-category {
    color: #c42cfc;
}

.slick-current .green {
    border: solid 1px #23ce6b;
}

.green .slider-link {
    background-color: #23ce6b40;
}

.slick-current .green .store-category {
    color: #23ce6b;
}

.slick-current .blue {
    border: solid 1px #4541df;
}

.blue .slider-link {
    background-color: #4541df40;
}

.slick-current .blue .store-category {
    color: #4541df;
}

.slick-current .red {
    border: solid 1px #ee5253;
}

.red .slider-link {
    background-color: #ee525340;
}

.slick-current .red .store-category {
    color: #ee5253;
}

.slick-current .red {
    border: solid 1px #ee5253;
}

.red .slider-link {
    background-color: #ee525340;
}

.slick-current .red .store-category {
    color: #ee5253;
}

.slick-current .babyBlue {
    border: solid 1px #00a9ff;
}

.babyBlue .slider-link {
    background-color: #00a9ff40;
}

.slick-current .babyBlue .store-category {
    color: #00a9ff;
}

.slick-current .yellow {
    border: solid 1px #feb301;
}

.yellow .slider-link {
    background-color: #feb30140;
}

.slick-current .yellow .store-category {
    color: #feb301;
}

/*** HomePage Banner (index.html) ***/
.parent-slider .slider-item {
    height: 293px;
}

.slider-item .fourth-slider {
    background-image: url(../images/slider/banner-4.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slider-item .fourth-slider .slider-play-icon {
    width: 99px;
    height: 99px;
    background: linear-gradient(to bottom, #f26d4c, #ef4f70);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 5em;
}

.slick-prev {
    left: 0;
}

/*** Main Tabs Col ***/
.main-tabs-col .main-header-title {
    padding: 39px 0;
    border-bottom: solid 1px #e4e5e6;
}

.main-header-title>i {
    width: 60px;
    height: 60px;
    padding: 16px 16px 15px;
    background-color: #f8f8f8;
    border-radius: 5em;
    margin-right: 16px;
}

.main-tabs-col .nav-tabs {
    border-bottom: solid 1px #e4e5e6;
}

.main-tabs-col .nav-tabs .nav-link {
    border: 0;
    padding: 20px 0 !important;
    border-bottom: 1px solid #e4e5e6;
    margin-bottom: -1px;
    transition: 0.2s all ease-in-out;
    color: #2c2c2c;
}

.main-tabs-col .nav-tabs .nav-link.active {
    /* font-weight: 600; */
    border-bottom: 1px solid #f05864;
}

.main-tabs-col .nav-tabs .nav-link i {
    margin-right: 12px;
    width: 27px;
    height: 27px;
    border-radius: 8px;
    background-color: #f8f8f8;
    font-size: 13px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.main-tabs-col .nav-tabs .nav-link.active i {
    background-color: #f05864;
    color: #fff;
}

.main-tabs-col .nav-tabs .nav-item {
    margin-right: 50px !important;
}

.main-tabs-col .nav-tabs .nav-item:last-child {
    margin-right: 0 !important;
}

.main-tabs-col .tab-content {
    margin-top: 35px;
}

/*** Profile - Withdraw Money (profile-withdraw.html) ***/
.online-transfer .second-header-title {
    margin-bottom: 14px;
}

.online-transfer .transfer-description {
    color: #2c2c2c;
    opacity: 0.7;
    font-size: 15px;
    margin-bottom: 53px;
}

.online-transfer .add-bank-btn a {
    color: #2c2c2c;
    line-height: 3.625;
    display: inline-block;
    border: dashed 1px #c7c7c7;
    border-radius: 6px;
    width: 100%;
    text-align: center;
}

.online-transfer .withdrawal-amount p {
    color: #2c2c2c;
    opacity: 0.8;
    margin-bottom: 16px;
}

.online-transfer .withdrawal-amount input {
    border-radius: 6px;
    border: solid 1px #e8e8e8;
    font-weight: 600;
    color: rgba(44, 44, 44, 0.24);
    padding: 16px 25px;
    width: 100%;
}

.online-transfer .withdrawal-amount input::placeholder {
    color: rgba(44, 44, 44, 0.24);
}

.withdraw-btn-upper {
    margin-top: 74px;
}

.withdraw-btn-upper .withdraw-btn {
    border-radius: 8px;
    background-color: #e4e5e6;
    color: #fff;
    display: inline-block;
    padding: 19px 0;
    width: 100%;
    text-align: center;
    border: 0;
}

.withdraw-btn-upper .withdraw-btn.active {
    background: linear-gradient(135deg, #f0536c 21%, #f37246 104%);
    color: #fff !important;
}

.withdraw-btn-upper .withdraw-btn:hover.active {
    background: #ff6868;
}

/* Extra small devices (portrait phones, less than 576px) profile-withdraw.html */
@media (max-width: 575.98px) {
    .main-tabs-col .nav-tabs .nav-item {
        margin-right: 0;
        margin: 0 !important;
    }

    .main-tabs-col .nav-tabs {
        justify-content: space-between;
        flex-wrap: unset;
    }

}

/* Add Bank form Modal */
.online-transfer-form {
    padding-right: 0 !important;
}

.online-transfer-form .modal-dialog {
    max-width: 750px;
}

.online-transfer-form .modal-content {
    border: 0;
    border-radius: 14px;
}

.online-transfer-form .modal-header {
    position: relative;
    padding: 2.7rem 1rem;
    background-size: cover;
    background-image: url(../images/home/bg-3.png);
    background-position: center right;
    border-bottom: 0;
    border-top-right-radius: 14px;
    border-top-left-radius: 14px;
}

.online-transfer-form .modal-header .modal-title-upper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.online-transfer-form .modal-header .modal-title-upper .modal-title {
    color: #2c2c2c;
    line-height: 1.35;
    font-size: 26px;
    font-weight: 600;
    margin-top: 10px;
}

.online-transfer-form .modal-header .modal-title-upper .title-icon {
    background-image: linear-gradient(155deg,
            rgba(240, 88, 100, 0.07) 24%,
            rgba(242, 109, 76, 0.07) 92%);
    padding: 13px;
    border-radius: 5em;
}

.online-transfer-form .modal-header .modal-title-upper .title-icon i {
    padding: 17px 15px 16px 16px;
    box-shadow: 0 26px 36px 0 rgba(188, 47, 58, 0.44);
    background: linear-gradient(157deg, #f26d4c 22%, #f05864 96%);
    border-radius: 5em;
    color: #fff;
}

.online-transfer-form .modal-header .close {
    position: absolute;
    top: 30px;
    right: 63px;
    opacity: 1;
    color: #2c2c2c;
    border: 0;
}

.online-transfer-form .modal-footer {
    justify-content: center;
    border-top: 0;
    padding-bottom: 25px;
    padding-top: 14px;
}

.online-transfer-form .modal-footer .btn {
    padding: 19px 72px 19px 72px;
    border-radius: 8px;
    /* background: linear-gradient( 90deg, rgba(239, 79, 112, 1) 7%, rgba(242, 109, 76, 1) 84% ); */
    font-weight: 600;
    border: unset;
    margin: 0;
}

.online-transfer-form form.add-bank-account .form-group .form-label {
    margin-bottom: 18px;
    opacity: 0.8;
    color: #2c2c2c;
    line-height: 1.44;
    font-weight: normal;
    letter-spacing: normal;
    text-transform: capitalize;
    font-size: 1em;
}

.online-transfer-form form.add-bank-account .form-group .form-control {
    padding: 17px 24px;
    border-radius: 6px;
    border: solid 1px #e8e8e8;
    background-color: #f8f8f8;
    color: rgba(44, 44, 44, 0.8);
    height: 100%;
}

.online-transfer-form form.add-bank-account {
    margin-top: 14px;
}

.online-transfer-form form.add-bank-account .form-group select.form-control {
    position: relative;
    background-position: calc(100% - 24px) 24px;
}

.online-transfer-form form.add-bank-account .form-group select.form-control *:hover {
    background-color: #f05864;
    color: #fff;
}

.online-transfer-form form.add-bank-account .form-group {
    margin-bottom: 1rem;
}

.cards-wrapper {
    margin-bottom: 60px;
}

.cards-wrapper label {
    display: block;
}

.card-details {
    border-radius: 6px;
    border: solid 2px #e4e5e6;
    background: url(../images/home/bg-3.png);
    background-size: cover;
    transition: 0.2s border ease-in-out;
}

.card-details:hover,
input:checked+label .card-details {
    border: solid 2px #f05864;
}

.card-details .verification-panel {
    padding: 5px 8px;
    font-size: 10px;
    color: #2c2c2c;
    opacity: 0.8;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    /* margin-left: -1px; */
}

.card-details .verification-panel a {
    color: #f05864;
    text-decoration: underline !important;
}

.card-details .verification-panel.unverified {
    background-color: #ffd4d7;
}

.card-details .verification-panel.verified {
    background-color: #dcffd8;
}

.card-details .card-information {
    padding-right: 15px;
    padding-left: 46px;
    margin-top: 11px;
    margin-bottom: 40px;
    position: relative;
}

.setting-dropdown {
    position: absolute;
    top: 0;
    right: 15px;
    padding: 8px;
    border-radius: 5em;
    background-color: #fff;
    font-size: 13px;
}

.setting-dropdown .dropdown-menu {
    padding: 0;
    margin: 0;
    border: 0;
    box-shadow: 0 8px 36px 0 rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    transform: translate3d(-125px, 43px, 0px) !important;
}

.setting-dropdown .dropdown-menu .dropdown-item {
    color: #2c2c2c;
    font-size: 13px;
    font-weight: 300;
    line-height: 1;
    transition: 0.2s all ease-in-out;
}

.setting-dropdown .dropdown-menu .dropdown-item:first-child {
    padding: 14px 0.75rem;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.setting-dropdown .dropdown-menu .dropdown-item:last-child {
    padding: 14px 0.75rem;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.setting-dropdown .dropdown-menu .dropdown-item:hover {
    background-color: #f05864;
    color: #fff;
}

.setting-dropdown .dropdown-menu .dropdown-item i {
    margin-right: 14px;
}

.card-details .card-information .bank-logo {
    margin-top: 7px;
}

.card-details .card-information .bank-logo img {
    max-height: 37px;
}

.card-details .card-information .card-number {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 40px;
    margin-top: 23px;
    margin-bottom: 13px;
}

.card-details .card-information .card-number span {
    color: rgba(44, 44, 44, 0.81);
    font-size: 15px;
}

.card-details .card-information .card-user-name {
    margin-bottom: 38px;
}

.card-details .card-information .card-user-name h6 {
    color: #2c2c2c;
    line-height: 1.4;
    font-weight: 600;
    font-size: 16px;
}

.card-details .card-information .paypal-user-email {
    color: rgba(44, 44, 44, 0.7);
    font-size: 14px;
}

.card-details.paypal-card .bank-logo {
    margin-bottom: 30px;
}

/*** Profile - Account Information (profile-information.html) ***/
.unverified-account-toast {
    padding: 11px 0 11px 9px;
}

.unverified-account-toast p {
    color: rgba(44, 44, 44, 0.8);
    font-size: 14px;
}

.unverified-account-toast img {
    margin-right: 3px;
    height: 1.5em;
    padding: 3px;
}

.profile-information form label {
    color: #2c2c2c;
    font-size: 16px;
    font-weight: normal;
    opacity: 0.8;
    cursor: unset;
}

.profile-information form label .gender-optional {
    color: #a7a9ac;
    opacity: 0.8;
    font-size: 14px;
}

.profile-information form .form-control {
    color: rgba(44, 44, 44, 0.8);
    border-radius: 6px;
    border: solid 1px #e8e8e8;
    padding-left: 24px;
}

.profile-information form .form-control.select-country {
    background-position: 93%;
}

.profile-information .form-control {
    height: 60px;
}

.profile-information .input-group-prepend {
    margin-top: unset;
    position: static;
    height: auto;
}

.profile-information form input[type="tel"] {
    padding-left: 20px;
    border: 0;
    margin: 0;
    height: 58px;
}

.profile-information .input-group-prepend select {
    height: auto;
    padding: 0 34px 0 17px;
    margin: 17px 0;
    width: auto;
    background-position: 84%;
    border-right: solid 2px #e8e8e8;
}

.profile-information .input-group.button-with-select {
    border-radius: 6px;
    border: solid 1px #e8e8e8;
    margin-bottom: 38px;
}

.profile-information .gender-toggle .btn-group-toggle {
    width: 100%;
    border: solid 1px #e8e8e8;
    background-color: #f8f8f8;
    padding: 5px;
    border-radius: 6px;
    margin-bottom: 22px;
}

.profile-information .gender-toggle .btn-group-toggle .btn {
    color: rgba(44, 44, 44, 0.8);
    padding: 0.75rem;
    background-color: transparent;
    border: 0;
    border-radius: 6px;
    box-shadow: none;
    margin-bottom: 0;
    cursor: pointer;
}

.profile-information .gender-toggle .btn-group-toggle .btn.active {
    background: #f0536c;
    background: linear-gradient(135deg, #f0536c 21%, #f37246 104%);
    color: #fff !important;
    opacity: 1;
}

.profile-information .update-information-btn {
    margin-top: 42px;
}

/*** Earn More Page (Earn-More.html) ***/
.earn-more-description {
    padding: 50px 0;
}

.store-card {
    display: flex;
    justify-content: space-between;
    padding: 1.75rem;
}

.store-card:not(:last-child) {
    border-bottom: solid 1px #e4e5e6;
}

.store-card .card-body {
    display: flex;
    align-items: center;
    padding: 0;
}

.store-card .card-body .card-text h4 {
    font-size: 17px;
    opacity: 0.8;
    color: #000;
    line-height: 24px;
    margin-bottom: 0;
}

.store-card .card-body .card-text p {
    color: #000;
    font-size: 15px;
    opacity: 0.8;
    font-weight: normal;
    line-height: 21px;
    margin-top: 6px;
    margin-bottom: 0;
}

.store-card .card-body .card-logo {
    border: solid 1px #fbfbfb;
    border-radius: 3px;
    margin-right: 13px;
    width: 61px;
    height: 61px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.store-card .card-btn p {
    margin-bottom: 0;
    color: #000;
    opacity: 0.8;
    font-size: 12px;
    line-height: 17px;
}

.store-card .card-btn a {
    border-radius: 2px;
    background-color: #f05864;
    color: #fcfcfc;
    border: 1px solid #fcfcfc;
    font-size: 12px;
    margin-top: 11px;
    padding: 0.22rem 1.46rem;
    display: inline-block;
    transition: 0.3s all ease-in-out;
}

.store-card .card-btn a:hover {
    background-color: #fcfcfc;
    color: #f05864;
    border: 1px solid #f05864;
}

.earn-more aside {
    border-radius: 8px;
    border: solid 1px #e4e5e6;
}

.side-card {
    padding: 17px 24px;
}

.side-card:not(:last-child) {
    border-bottom: solid 1px #e4e5e6;
}

.side-card.invitation {
    display: flex;
}

.side-card.invitation .card-icon img {
    max-width: 42px;
}

.side-card.invitation .card-body {
    padding: 0;
    margin-left: 25px;
}

.side-card.invitation .card-body * {
    margin-bottom: 0;
    color: #000;
    opacity: 0.8;
}

.side-card.invitation .card-body .card-body-title {
    font-size: 20px;
    line-height: 28px;
}

.side-card.invitation .card-body .card-body-description {
    font-size: 15px;
    line-height: 21px;
}

.side-card.reward-code {
    background-color: #f8f8f8;
}

.side-card.reward-code .reward-code-title {
    color: #000;
    font-size: 15px;
    font-weight: 500;
    line-height: 21px;
    margin-bottom: 10px;
}

.side-card.reward-code .reward-code-input {
    padding: 12px 20px;
    border-radius: 3px;
    background-color: #fff;
    color: #000;
    font-size: 15px;
    opacity: 0.5;
    width: 100%;
    border: 0;
}

.side-card.how-work .card-body {
    padding: 34px 34px 17px 0;
}

.side-card.how-work .card-body .card-body-title {
    margin-bottom: 24px;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    color: #2c2c2c;
}

.side-card.how-work .card-body .card-body-title i {
    margin-right: 1rem;
}

.side-card.how-work .card-body .card-body-description {
    color: #1d1d1d;
    font-size: 15px;
    opacity: 0.8;
    margin-bottom: 0;
}

.empty-area .circle-icon {
    width: 135px;
    height: 135px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f8f8f8;
    border-radius: 5em;
    margin-bottom: 10px;
}

.empty-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 140px 0;
}

.empty-area .empty-area-description h4 {
    color: #000;
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 0;
}

.empty-area .empty-area-description p {
    color: #949698;
    font-size: 12px;
    margin-bottom: 0;
}

/*** Earn More (How To Play) Page (Earn-More(How To Play).html) ***/
.store-full-card {
    margin-top: -60px;
    margin-bottom: 60px;
}

.store-full-card .store-card {
    background-color: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: none;
}

.store-full-card .store-card .card-body .card-text h4 {
    color: #2c2c2c;
    font-size: 28px;
    font-weight: 600;
    opacity: 1;
}

.store-full-card .store-card .card-body .card-text h4 span {
    font-size: 18px;
    font-weight: normal;
    color: #2c2c2c;
}

.store-full-card .store-card .card-body .card-text p {
    color: #f05864;
    font-size: 18px;
    font-weight: 500;
}

.store-full-card .store-card .card-btn a {
    border-radius: 8px;
    padding: 19px 50px 19px 51px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 23px;
    border: none;
}

.store-full-card .store-card .card-btn a:hover {
    color: #fff;
}

.store-full-card .btn-primary {
    background-color: #daf0fb;
    color: #0c6c9c;
    letter-spacing: 0.47px;
    font-size: 15px;
    font-weight: 600;
    border: none;
    padding: 1.05rem 0px;
    border-radius: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
    .store-full-card .store-card {
        flex-direction: column;
    }

    .store-full-card .store-card .card-btn {
        text-align: center;
        margin-top: 17px;
    }
}

.main-steps .single-step .step-icon {
    width: 144px;
    height: 144px;
    background: linear-gradient(155deg, #f05864 24%, #f26d4c 92%);
    border-radius: 5em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.main-steps .single-step .step-icon i {
    width: 96px;
    height: 96px;
    box-shadow: 0 26px 36px 0 #bc2f3a;
    background: linear-gradient(157deg, #f26d4c 22%, #f05864 96%);
    color: #fff;
    font-size: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5em;
}

.main-steps .single-step .step-title {
    margin-top: 42px;
    font-size: 18px;
    font-weight: 600;
    line-height: 31px;
    color: #2c2c2c;
    text-align: center;
    margin-bottom: 0;
}

.main-steps .steps-title {
    font-size: 32px;
    font-weight: 600;
    line-height: 59px;
    color: #2c2c2c;
    margin-bottom: 0;
}

.main-steps .steps-description {
    color: #2c2c2c;
    font-size: 16px;
    line-height: 31px;
    margin-bottom: 0;
}

.main-steps .steps-description span {
    font-weight: 500;
}

.game-participant-holder {
    margin-top: 58px;
}

.game-participant {
    padding: 56px 0;
    background-color: #fbfbfb;
}

.game-participant .reward-title {
    color: #2c2c2c;
    font-size: 22px;
    line-height: 31px;
    margin: 0 auto 27px;
}

.game-participant .game-statistic {
    background-color: #fff;
    padding: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.game-participant .game-statistic p {
    margin-bottom: 0;
    color: #000;
    opacity: 0.8;
    font-size: 21px;
    line-height: 29px;
}

.game-participant .game-statistic .participants-number {
    color: #f05864;
    opacity: 0.8;
    font-size: 27px;
    font-weight: 500;
    line-height: 37px;
    margin: 0 15px;
}

.game-participant .game-statistic .icon {
    width: 67px;
    height: 67px;
    border-radius: 5em;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f05864;
    font-size: 26px;
    font-weight: 300;
}

.game-participant .game-statistic .participant-icon {
    background-color: #fbebec;
}

.game-statistic .winner-icon-holder {
    width: 150px;
    height: 150px;
    background: none;
    position: relative;
}

.game-statistic .winner-icon-holder::after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 6px solid #eee;
    position: absolute;
    top: 0;
    left: 0;
}

.game-statistic .winner-icon-holder>span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
}

.game-statistic .winner-icon-holder .progress-left {
    left: 0;
}

.game-statistic .winner-icon-holder .progress-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: 6px;
    border-style: solid;
    position: absolute;
    top: 0;
}

.game-statistic .winner-icon-holder .progress-left .progress-bar {
    left: 100%;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
}

.game-statistic .winner-icon-holder .progress-right {
    right: 0;
}

.game-statistic .winner-icon-holder .progress-right .progress-bar {
    left: -100%;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    border-right: 0;
    -webkit-transform-origin: center right;
    transform-origin: center right;
}

.game-statistic .winner-icon-holder .winner-icon {
    position: absolute;
    top: 0;
    left: 0;
}

.simple-question {
    margin-top: 50px;
}

.simple-question .main-title {
    color: #2c2c2c;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 30px;
}

.simple-question .challenge-description {
    /* font-size: 18px; */
    color: #1d1d1d;
    margin-bottom: 2rem;
}

.simple-question .answers-list {
    /* font-size: 16px; */
    color: #1d1d1d;
    margin-bottom: 0;
    padding-left: 1rem;
}

.simple-question .answers-list li {
    margin-bottom: 2rem;
}

/*** Help - Contact Us Tab (help.html) ***/
.help-tabs .contact-us-tab .tab-header {
    padding: 31px 0;
    border-bottom: solid 1px #e4e5e6;
    margin-bottom: 45px;
    font-size: 24px;
    font-weight: 600;
    line-height: 48px;
}

.help-tabs .contact-us-tab .contact .main-title,
.help-tabs .contact-us-tab .contact .main-title span {
    font-size: 25px;
    font-weight: 600;
    color: #2c2c2c;
    line-height: 49px;
    margin-bottom: 0;
}

.help-tabs .contact-us-tab .contact .sub-title {
    color: #949698;
    line-height: 24px;
    margin-bottom: 0;
}

.help-tabs .contact-us-tab .contact .list-contact-form>.form-group {
    margin-bottom: 40px;
    margin-top: 47px;
}

.help-tabs .contact-us-tab .contact .list-contact-form .form-group label {
    color: #2c2c2c;
    opacity: 0.8;
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 18px;
    font-weight: normal;
    letter-spacing: normal;
    text-transform: capitalize;
}

.help-tabs .contact-us-tab .contact .list-contact-form .form-group .form-control {
    border-radius: 6px;
    border: solid 1px #e8e8e8;
    padding: 18px 30px;
    height: auto;
    color: #2c2c2c;
    line-height: 22px;
    letter-spacing: 0.18px;
    background-position: 96%;
}

.topics-details .hide-topic {
    display: none;
}

.topics-details .form-group {
    display: flex;
    flex-direction: column;
}

.topics-details textarea {
    border-radius: 6px;
    border: solid 1px #e8e8e8;
    padding: 19px 30px;
    line-height: 23px;
    letter-spacing: 0.18px;
}

.topics-details textarea::placeholder,
.topics-details input::placeholder {
    color: #a7a9ac;
}

.topics-details .main-btn {
    margin-top: 14px;
    display: inline-flex;
    align-items: center;
}

.topics-details .main-btn i {
    margin-left: 13px;
}

.topics-details select {
    color: #a7a9ac;
    letter-spacing: 0.18px;
}

.muted-select {
    color: #a7a9ac !important;
}

/*** Main Components Edits ***/

/* Main Header */
.bottom-nav {
    background-color: #fff;
    border-bottom: 1px solid #f5f5f5 !important;
    margin-bottom: 15px;
}

form .search-box .btn-search {
    padding: 0;
    position: absolute;
    right: 13px;
    top: 0;
    bottom: 0;
    margin: auto;
}

.main-header .navbar .navbar-collapse .navbar-nav {
    align-items: center;
    width: 100%;
}

.main-header .navbar {
    padding: 20px 0;
}

.main-header .navbar-expand-lg .navbar-nav .nav-link {
    padding: 0;
    margin: 0 !important;
    display: flex;
    align-items: center;
}

.main-header .navbar-expand-lg .navbar-nav .nav-link.join-btn {
    padding: 1rem 2rem;
}

.main-header .navbar-expand-lg .navbar-nav .nav-link.join-btn:hover {
    background-color: transparent;
    /* border: 1px solid #fff !important; */
}

.nav-item.nav-item-search {
    margin-left: 30px;
    margin-right: 15px;
    width: 100%;
}

.nav-item.nav-item-search .form-inline {
    width: 100%;
}

.navbar-toggler span {
    background-color: #fff;
    border-radius: 0;
    height: 3px;
    margin-top: 5px;
    padding: 0;
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms;
    width: 30px;
    cursor: pointer;
    display: block;
}

.navbar-toggler.collapsed span:nth-child(1) {
    margin-top: 0;
    transform: none;
    top: 0;
}

.navbar-toggler.collapsed span:nth-child(2) {
    margin-left: 10px;
    opacity: 1;
}

.navbar-toggler.collapsed span:nth-child(3) {
    transform: none;
    top: 0;
}

.navbar-toggler span:nth-child(1) {
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    position: relative;
    top: 8px;
}

.navbar-toggler span:nth-child(2) {
    opacity: 0;
}

.navbar-toggler span:nth-child(3) {
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    position: relative;
    top: -7px;
}

.navbar-toggler:focus {
    box-shadow: none;
}

.bottom-nav .navbar-toggler span {
    background-color: #f05864;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: #e9ecef;
}



@media (min-width: 992px) {
    .bottom-nav .primary-menu.with-arrows {
        width: 100%;
    }

    .bottom-nav .primary-menu.with-arrows .menu-container {
        width: 100%;
    }
}

@media (max-width: 991px) {
    .categories-menu.show-sub-menu>.sub-menu-container {
        opacity: 1 !important;
        visibility: visible;
        margin-top: 0 !important;
        z-index: 399 !important;
        pointer-events: auto !important;
        display: block;
    }

    .navbar .navbar-collapse {
        position: fixed;
        left: -300px;
        top: 0;
        bottom: 0;
        background: linear-gradient(90deg,
                rgba(239, 79, 112, 1) 7%,
                rgba(242, 109, 76, 1) 84%);
        z-index: 999;
        width: 300px;
        transition: all 0.2s ease-in-out;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    }

    .navbar .navbar-collapse.show {
        left: 0;
    }

    .main-header .navbar .navbar-collapse .navbar-nav {
        padding: 2rem 1rem;
    }

    .main-header .navbar .navbar-collapse .navbar-nav .menu-item {
        width: 100%;
    }

    .nav-item {
        margin: 0 !important;
        padding: 0.5rem 0;
        width: 100%;
    }

    .nav-item:not(:first-child) {
        border-bottom: 1px solid #fff;
    }

    .header-buttons {
        border-left: 0;
        padding: 0;
        height: auto;
    }

    .main-header .navbar-expand-lg .navbar-nav .nav-link.join-btn {
        padding: 0;
        border: 0 !important;
    }

    .navbar-nav>.nav-item>.nav-link small {
        /* margin-right: auto; */
    }

    .navbar-nav>.nav-item>.nav-link:hover {
        color: #fff;
        background-color: transparent;
    }

    .categories-menu>.sub-menu-container {
        display: none;
    }

    .categories-menu>.sub-menu-container>.menu-item>.sub-menu-container {
        display: none;
    }

    .categories-menu>.sub-menu-container>.menu-item.show-second-sub-menu>.sub-menu-container {
        display: block;
    }

    .categories-menu>.sub-menu-container>.menu-item.show-second-sub-menu>.icon-chevron-right:before {
        content: "\e998";
    }

    .menu-item>.menu-link div {
        color: #fff;
    }

    .menu-item.show-second-sub-menu>.sub-menu-container>.menu-item>.menu-link div {
        color: #000;
    }

    .menu-item:hover>.menu-link div {
        color: #fff;
    }

    .mega-menu-content.mega-menu-style-2.how-to-earn {
        display: none;
    }

    .menu-item .how-to-earn+.sub-menu-trigger.icon-chevron-right {
        display: none;
    }

    .sub-menu-container .menu-item .sub-menu-trigger {
        top: 0;
        right: -14px;
        color: #fff;
    }

    .sub-menu-container .menu-item:hover>.menu-link div,
    .menu-item:hover>.menu-link div,
    .menu-item.current>.menu-link div {
        color: #fff;
    }

    .show-second-sub-menu+.menu-item {
        border-top: none !important;
    }

    .user-img-header {
        border-left: 0;
        padding-left: 0;
    }

    .nav-item .user-menu {
        background-color: #fff !important;
    }

    .nav-item .user-menu .flag-text {
        color: #212529;
    }
}

/* Top Selling section */
.slick-prev:before,
.slick-next:before {
    font-size: 30px;
    color: #ccc;
}

.Top-Selling-category {}

#popular .store-logo img {
    max-width: 100px;
}

#popular .single-pro h3 {
    font-size: 18px;
}

#popular .single-pro p {
    font-size: 16px;
}

.single-pro a {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.single-pro-wrapper {
    border: 1px solid #e4e5e6;
}

/* Featured Daily Deals & Discount section*/
.products-cards .card,
.products-cards .card>img {
    border-radius: 12px;
}

.logo-wrapper {
    background: #fff;
    padding: 10px 23px;
    text-align: center;
    border-radius: 10px;
    width: 146px;
    height: 78px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-bottom: 1rem;
    margin-top: -55px;
}

.logo-wrapper img {
    max-width: 60px;
}

.products-cards .card-content h3 {
    font-size: 20px;
    color: #1d1d1d;
    margin-bottom: 0;
}

.products-cards .card-content p {
    color: #f05864;
    font-weight: 600;
    font-size: 15px;
    margin-top: 9px;
    margin-bottom: 26px;
}

.products-cards .card-content .home-expire-icon {
    color: #2c2c2c;
    font-size: 12px;
    font-weight: 500;
    transition: 0.1s all ease-in-out;
}

.products-cards .card:hover {
    border: 1px solid #f05864;
}

.products-cards .card:hover .card-content .home-expire-icon {
    color: #f05864;
}

.products-cards .card:hover .card-content .home-expire-icon i.fa-stopwatch {
    background-color: #f0586430;
}

.products-cards .card {
    transition: 0.1s all ease-in-out;
}

/* Mobile App Component section */
.mobilestore-banner img {
    margin-top: -5rem;
}

/* Footer */
footer .category-title {
    font-size: 18px;
}

footer .fa-phone {
    transform: rotate(90deg);
}

footer .social .socialmedia {
    display: flex;
    justify-content: center;
    margin-bottom: 0;
}

footer .social .socialmedia li {
    width: 30px;
    height: 30px;
    background-color: transparent;
    border-radius: 5em;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.2s all ease-in-out;
    border: 1px solid #eee;
    margin-bottom: 1rem;
}

footer .social .socialmedia li:hover {
    background-color: #f0536c;
    border: 1px solid #f0536c;
}

footer .social .socialmedia li:hover i {
    color: #fff;
}

section.steps-section p {
    margin: 0;
}

.quick-links ul>a>li {
    color: #dedede;
    opacity: .7;
    margin-bottom: 0.5rem;
}
.quick-links ul>a>li:hover{
    opacity: 1;
}
.subscribe-form .message-icon {
    position: absolute;
    left: 22px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
}

.subscribe-form .message-icon i {
    color: #a7a9ac;
    font-size: 18px;
    font-weight: 300;
}

.subscribe-form .input-group>.form-control:focus,
.subscribe-form .input-group>.form-select:focus {
    z-index: unset;
}

/* Register Modal */
.modal-register {
    /* position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  background-color: #00000040; */
}

.modal-register .card-header .btn-close:focus {
    box-shadow: none;
}

.modal-register .card-header .btn-close {
    top: 8px;
    right: -10px;
    font-size: 12px;
}

.modal-register .card-header .icon-wrapper {
    width: 77px;
    height: 77px;
    background-image: linear-gradient(155deg, #f0586450 24%, #f26d4c50 92%);
    border-radius: 50%;
    margin: 0 auto;
}

.sign-up-form .card-header .main-icon {
    width: 77px;
    height: 77px;
    margin-bottom: 1rem;
}

.sign-up-form .card-header .main-icon i {
    width: 53px;
    height: 53px;
}

.sign-up-form .card-header .main-icon i::before {
    font-size: 18px;
}

.sign-up-form .modal-card-body .main-icon {
    width: 96px;
    height: 96px;
    background-image: linear-gradient(155deg, #ffee9038 24%, #f0586438 92%);
    margin: 0;
}

.sign-up-form .modal-card-body .main-icon i {
    width: 64px;
    height: 64px;
    box-shadow: 0 26px 36px 0 rgba(188, 47, 58, 0.44);
    background-image: linear-gradient(157deg, #fff 22%, #ffd1c6 96%);
}

.sign-up-form .modal-card-body .main-icon i::before {
    font-size: 23px;
    color: #f05864;
}

.join-steps h4 {
    font-size: 26px;
    font-weight: 600;
    color: #2c2c2c;
    margin-top: 22px;
    margin-bottom: 15px;
}

.join-steps .steps li {
    counter-increment: inst;
    margin-bottom: 19px;
}

.join-steps .steps li::before {
    content: counter(inst);
    color: #f05864;
    border: 1px solid #f05864;
    text-align: center;
    transition: all 0.2s ease-in-out;
    border-radius: 5em;
    font-size: 17px;
    font-weight: 500;
    width: 42px;
    height: 42px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
}

.sign-up-form .invalid-feedback {
    display: block;
}

.input-group.number-input:after {
    position: absolute;
    content: "";
    background-color: #e8e8e8;
    height: 70%;
    width: 1px;
    left: 100px;
    margin: auto;
    top: 0;
    bottom: 0;
    z-index: 3;
}

@media screen and (max-width: 576px) {
    .box-model {
        padding: 8px;
    }

    .box-model h3 {
        font-size: 16px !important;
    }

    .join-steps .steps li::before {
        width: 26px;
        height: 26px;
        margin-right: 10px;
        position: absolute;
        left: 0;
    }

    .join-steps .steps li {
        padding-left: 2.5rem;
        position: relative;
    }
}

label.terms {
    font-size: 15px;
    color: #2c2c2c;
    opacity: 1;
}

.termsConditionsModal .main-icon {
    width: 77px;
    height: 77px;
}

.termsConditionsModal .main-icon i {
    width: 51px;
    height: 51px;
}

.termsConditionsModal .main-icon i:before {
    font-size: 18px;
    font-weight: 300;
}

.termsConditionsModal .back-icon {
    position: absolute;
    top: 2rem;
    left: 3rem;
}

.termsConditionsModal .back-icon i {
    color: #2c2c2c;
    font-size: 24px;
}

/* Help Page */
.help-tabs .help-topic-wrapper .list-group-item {
    border: 0;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
    position: relative;
}

.help-tabs .help-topic-wrapper {
    border: solid 1px #e4e5e6;
    border-radius: 0.4rem;
}

.help-topic-wrapper .help-topic {
    margin-bottom: 0;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.help-topic-wrapper .help-topic h5 {
    font-size: 24px;
}

.help-banner-wrapper .help-banner {
    background-image: url(../images/help/help-banner.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0.5em;
}

.help-tabs .help-topic-wrapper .list-group-item:after {
    width: 2px;
    height: 100%;
    background-color: #f05864;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: none;
}

.help-tabs .help-topic-wrapper .list-group-item.active:after {
    display: block;
}

@media (max-width: 991.98px) {
    .help-tabs .list-group {
        /* flex-direction: row; */
    }

    .help-tabs .list-group-item {
        padding: 0.5rem;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0 !important;
    }

    .help-tabs .list-group-item.active {
        background-color: #f05864;
        color: #fff !important;
        border-radius: 0.3rem;
    }

    .help-tabs .help-topic-wrapper .list-group-item {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        padding-left: 0.5rem;
        text-align: center;
    }

    .help-tabs .help-topic-wrapper .list-group-item.active:after {
        display: none;
    }

    .help-topic-wrapper .help-topic {
        padding-left: 1rem;
    }
}

@media (min-width: 992px) and (max-width: 991.98px) {

    .help-topic-wrapper .help-topic,
    .help-tabs .help-topic-wrapper .list-group-item {
        padding-left: 20px;
    }
}

/* About Page */
.banner-wrapper {
    background-image: url(../images/about-page/about-banner.svg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 0.4rem;
    margin: 45px 0;
    padding: 45px 0;
}

.banner-wrapper .banner-info span,
.banner-wrapper .banner-info h2 {
    color: #fff;
}

.banner-wrapper .banner-info span {
    font-size: 20px;
    margin-bottom: 28px;
    display: block;
}

.banner-wrapper .banner-info h2 {
    font-size: 43px;
    font-weight: 600;
}

/* Contact Page */
.contact-page form select,
.contact-page form textarea {
    border: solid 1px #e8e8e8;
}

.contact-page form .form-label {
    opacity: 0.8;
    color: #2c2c2c;
    margin-bottom: 18px;
}

/* Stores Page */
.single-store {
    border: solid 1px #e4e5e6;
    border-radius: 8px;
    position: relative;
    height: 100%;
}

.single-store .store-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.single-store .store-details .store-logo-wrapper {
    margin-bottom: 1rem;
    margin-top: 1.5rem;
}

.single-store .store-details .store-logo-wrapper img {
    max-width: 100px;
}

.single-store .icon-wrapper {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: rgba(240, 88, 100, 0.1);
    width: 30px;
    height: 30px;
    border-radius: 5em;
    color: #f05864;
    display: flex;
    align-items: center;
    justify-content: center;
}

.single-store .store-details h3 {
    font-size: 20px;
    color: #1d1d1d;
    font-weight: 500;
}

.single-store .store-details p {
    font-size: 18px;
    color: #f05864;
    font-weight: 600;
    text-align: center;
}

@media (max-width: 571.98px) {
    .single-store .store-details p {
        font-size: 14px;
    }
}

/* Cash Back by category page */
.second-header .star-icon-wrapper {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background-color: #f8f8f8;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.offers-wrapper .offer-box {
    padding: 2rem 0;
}

.offers-wrapper .offer-box:not(:last-child) {
    border-bottom: solid 1px #e4e5e6;
}

.offer-details .offer-details-header .offer-kind {
    color: #2c2c2c;
}

.offer-details .offer-details-header .expire-time {
    color: #f37246;
    font-size: 14px;
}

.offer-details .offer-details-header .expire-time .icon-wrapper {
    background-color: rgba(240, 88, 100, 0.1);
    width: 30px;
    height: 30px;
    border-radius: 5em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.offer-details .offer-title {
    margin: 22px 0;
    color: #2c2c2c;
    font-size: 18px;
    font-weight: 600;
}

.offer-details .cashback-percent {
    color: #f05864;
    font-weight: 500;
    margin-bottom: 0;
}

/* Widget Trending Stores */
.trending-stores .single-store {
    display: flex;
    align-items: center;
    border: 0;
}

.trending-stores .single-store .store-logo {
    border: solid 1px #e4e5e6;
    border-radius: 8px;
    padding: 20px 13px;
    width: 68px;
    height: 68px;
    background-color: #fff;
}

.trending-stores .single-store .store-content-box .store-name {
    font-weight: 600;
    color: #1d1d1d;
    font-size: 16px;
    margin-bottom: 0;
}

.trending-stores .single-store .store-content-box .cashback-percent {
    font-size: 14px;
    font-weight: 500;
    color: #f05864;
    margin-top: 8px;
    margin-bottom: 0;
}


/* Cashback Overview */
.cashback-overview-table thead {
    border-radius: 8px;
    background-color: #f8f8f8;
}

.cashback-overview-table tbody {
    border-top: 0 !important;
}

.cashback-overview-table tbody tr:last-child td {
    border-bottom-width: 0;
}

.cashback-overview-table tbody tr:first-child td {
    border-top: 1px solid #dee2e6;
}

.cashback-overview-table thead tr th,
.cashback-overview-table tbody tr td {
    padding-top: 20px;
    padding-bottom: 20px;
}

.cashback-overview-table thead tr th {
    color: #1d1d1d;
    font-size: 16px;
    font-weight: 400;
    border-bottom: 0;
}

.cashback-overview-table tbody::before {
    vertical-align: inherit;
    content: "@";
    display: block;
    line-height: 50px;
    text-indent: -99999px;
}

.cashback-overview-table .td-details .main-info {
    color: #1d1d1d;
    font-weight: 500;
}

.cashback-overview-table .td-details .main-info.status-pending {
    color: #f05864;
}

.cashback-overview-table .td-details .main-info.status-paid {
    color: #2ad1a4;
}

.cashback-overview-table .td-details .second-info {
    font-size: 14px;
    color: #1d1d1d;
    opacity: 0.6;
    font-weight: 500;
    margin-top: 7px;
}

/* Extra small devices (portrait phones, less than 576px) profile-withdraw.html */
@media (max-width: 575.98px) {
    .cashback-overview-table {
        display: flex;
    }

    .cashback-overview-table>thead>tr {
        height: 100%;
    }

    .cashback-overview-table thead tr th {
        height: 100%;
    }

    .cashback-overview-table tbody {
        flex-direction: row;
        overflow: auto;
        display: flex;
    }

    .cashback-overview-table tr {
        display: flex;
        flex-direction: column;
    }

    .cashback-overview-table tbody tr:first-child td {
        border-top: 0;
    }

    .cashback-overview-table tbody tr:last-child td {
        border-bottom-width: 1px;
    }

    .main-tabs-col .nav-tabs .nav-link {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .main-tabs-col .nav-tabs .nav-link i {
        margin-right: 0;
        margin-bottom: 1rem;
    }

    .online-transfer-form .modal-header .modal-title-upper .modal-title {
        text-align: center;
        font-size: 16px;
    }
}
