/* You can add global styles to this file, and also import other style files */
@import '~ngx-sharebuttons/themes/modern/modern-dark-theme.scss';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }



.error-message {
    height: auto;
    background-color: darkred;
    padding: 10px;
    display: flex;
    color:white;
    margin-bottom: 30px;
    margin-top: 30px;
}